@font-face {
  font-family: "Graphik-Bold";
  src: url("../fonts/Graphik-Bold.eot");
  src: url("../fonts/Graphik-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Bold.woff2") format("woff2"),
    url("../fonts/Graphik-Bold.woff") format("woff"),
    url("../fonts/Graphik-Bold.ttf") format("truetype"),
    url("../fonts/Graphik-Bold.svg#Graphik-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Medium";
  src: url("../fonts/Graphik-Medium.eot");
  src: url("../fonts/Graphik-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Medium.woff2") format("woff2"),
    url("../fonts/Graphik-Medium.woff") format("woff"),
    url("../fonts/Graphik-Medium.ttf") format("truetype"),
    url("../fonts/Graphik-Medium.svg#Graphik-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Light";
  src: url("../fonts/Graphik-Light.eot");
  src: url("../fonts/Graphik-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Light.woff2") format("woff2"),
    url("../fonts/Graphik-Light.woff") format("woff"),
    url("../fonts/Graphik-Light.ttf") format("truetype"),
    url("../fonts/Graphik-Light.svg#Graphik-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Regular";
  src: url("../fonts/Graphik-Regular.eot");
  src: url("../fonts/Graphik-Regular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Regular.woff2") format("woff2"),
    url("../fonts/Graphik-Regular.woff") format("woff"),
    url("../fonts/Graphik-Regular.ttf") format("truetype"),
    url("../fonts/Graphik-Regular.svg#Graphik-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphik-Semibold";
  src: url("../fonts/Graphik-Semibold.eot");
  src: url("../fonts/Graphik-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Graphik-Semibold.woff2") format("woff2"),
    url("../fonts/Graphik-Semibold.woff") format("woff"),
    url("../fonts/Graphik-Semibold.ttf") format("truetype"),
    url("../fonts/Graphik-Semibold.svg#Graphik-Semibold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  font-style: normal;
  font-family: "Graphik-Regular" !important;
  background: #fff;
  overflow-x: hidden;
}
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-style: normal;
}
button,
input,
select,
textarea {
  outline: 0 !important;
}
a,
a:focus,
a:hover {
  text-decoration: none !important;
}
a:focus,
button:focus,
input:focus {
  outline: 0 none;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
table {
  border-spacing: 0;
  border-collapse: collapse;
}
td,
th {
  padding: 0;
}
label {
  font-weight: 400;
  margin-bottom: 0;
}

.list-group-item {
  display: inline-block !important;
}
.slick-initialized .slick-slide {
  outline: 0 !important;
}
.container {
  position: relative;
}
.scroll-x{
  .row {
    .col-12{
      overflow-x: auto;
    }
  }
}
input::-webkit-input-placeholder {
  line-height: normal !important;
}
input,
textarea {
  border-radius: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.img-responsive {
  width: auto;
  height: 100%;
}

$primary-bg: #555555; //Primary color

$header-bg: #a1a1a1; //Header background
$thead-bg: #5d5d5d; //Table head background

$tc-wt: #fff; //Text color white
$tc-bk: #000; //Text color block
$tc-gray: #555555; //Text color gray

//Font fontfamily
$font-regular: "Graphik-Regular";
$font-medium: "Graphik-Medium";
$font-bold: "Graphik-Bold";
$font-light: "Graphik-Light";
$font-semibold: "Graphik-Semibold";

//Primary button style
$p-btn-bg: #555555; //primary button background
$p-btn-tc: #fff; //Primaty button text colour

$p-btn-disable-bg: #e9e9e9; //primary button background
$p-btn-disable-tc: #959595; //Primaty button text colour

@media (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}
@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}

.btn.btn-brown {
  width: 140px;
  background: $thead-bg;
  border-radius: 5px;
  position: relative;
  font: 16px/20px $font-medium;
  color: $tc-wt;
  &:focus {
    box-shadow: none !important;
  }
}
// Common style > start
.admin-content-wrapper {
  h1.head {
    font: 30px/24px $font-bold;
    color: $tc-gray;
    padding-top: 25px;
    padding-bottom: 15px;
  }
}
.common-para {
  font: 18px/22px $font-regular;
  color: $tc-gray;
}
.btn-primary:focus {
  box-shadow: none !important;
}
.btm-strip {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  z-index: 2;
  text-align: right;
  .proceed-btn-group {
    background: #000;
    // margin-left: 16.6666666667%;
    a {
      display: inline-block;
      font: 20px/20px $font-medium;
      color: $tc-wt;
      background: #2b2b2b;
      padding: 15px 40px;
      text-transform: uppercase;
    }
    a.disabled {
      pointer-events: none;
      color: #959595;
    }
  }
}
#no-results,
#no-results1 {
  display: none;
  padding: 8px 20px 0 20px;
  font: 16px/16px $font-medium;
  color: $tc-bk;
}
// Common style > end

/* header > start */
.admin-header {
  z-index: 1;
  position: relative;
  top: 0px;
  h4 {
    font: 20px/24px $font-regular;
  }
  .navbar {
    background: $header-bg;
  }
}
/* header > end */

/* sidebar > start */
.admin-sidebar {
  padding-top: 40px;
  .list-group .list-group-item span {
    font: 18px/20px $font-medium;
    color: $tc-gray;
  }
  .list-group .list-group-item span:hover {
    font-family: $font-bold;
    border-bottom: 1px solid #000;
  }
  .list-group .list-group-item.active {
    background-color: transparent;
  }
  .list-group .list-group-item.active span {
    font-family: $font-bold;
    background-color: transparent;
    border-bottom: 1px solid #000;
  }
  .list-group .list-group-item span.active {
    font-family: $font-bold;
    background-color: transparent;
    border-bottom: 1px solid #000;
  }
}
/* sidebar > end */

.newcall-parent {
  padding-bottom: 80px;
}

.admin-dashboard {
  padding-bottom: 50px;
  h2 {
    font: 16px/20px $font-medium;
    color: $tc-wt;
    padding: 10px 0;
    background: #000;
    text-align: center;
    margin-bottom: 10px;
  }
  .open-calls-blk {
    padding-top: 20px;
    &.typ-head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .load-btn{
        // padding: 5px 15px;
      //   font: 14px/18px $font-medium;
      // // color: $tc-wt;
      // padding: 8px 12px;
      // // background: #000;
      // // text-align: center;
      margin-right: 40px;


      // display: block;
  background: $thead-bg;
  border-radius: 5px;
  font: 16px/20px $font-medium;
  color: $tc-wt;
  text-align: center;
  padding: 10px;
  
      }
    }
    .das-table-blk {
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      .table {
        border-color: #000;
      }
      .table tr {
        text-align: center;
        border: 0;
      }
      .table td {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        border: 1px solid #000;
        width: 113px;
      }
      .table thead tr th {
        font: 16px/20px $font-medium;
        color: $tc-bk;
      }
      .table thead tr.bg th,
      .table tbody tr.bg td {
        background: #a1a1a1;
        font: 16px/20px $font-medium;
        color: $tc-bk;
      }
      .table thead tr.bg th.bg-none,
      .table tbody tr.bg td.bg-none {
        background: transparent;
      }
      .table tbody tr.num td {
        font: 34px/40px $font-bold;
        color: #ff9900;
      }
      .table tbody tr.fs td {
        font: 22px/22px $font-medium;
      }
    }
    .box {
      height: 78%;
      background: #555555;
      border: 2px solid #000;
      margin-top: 10px;
      h2 {
        font: 20px/20px $font-bold;
        color: $tc-wt;
        background: transparent;
        span {
          font: 16px/20px $font-regular;
          padding-top: 10px;
        }
        strong {
          font: 16px/20px $font-regular;
          padding-top: 5px;
        }
      }
    }
  }
  .close-call-blk {
    padding-bottom: 10px;
    .box {
      table {
        width: 100%;
        border-color: #000;
        margin-bottom: 0;
      }
      table thead tr,
      table tbody tr {
        text-align: center;
      }
      table tbody tr td {
        font: 16px/20px $font-medium;
        vertical-align: middle;
      }
      table thead tr th,
      table tbody tr td {
        padding: 8px 5px;
      }
      table thead tr th {
        background: #a1a1a1;
      }
      table tbody tr td.fs {
        font: 22px/26px $font-bold;
        color: #ff9900;
      }
      table tbody tr td > table tr td {
        text-align: center;
      }
      tbody tr td > table {
        background: #a1a1a1;
      }
      tbody tr td > table thead tr th {
        text-decoration: underline;
      }
    }
  }
  .sales-blk {
    padding-bottom: 10px;
    .box {
      background: #eeeeee;
      padding: 40px 20px;
      margin-bottom: 10px;
      min-height: 195px;
      h2 {
        background: transparent;
        font: 18px/22px $font-medium;
        color: $tc-bk;
        strong {
          font: 34px/38px $font-bold;
          color: $tc-wt;
          padding-top: 10px;
          color: #ff9900;
        }
      }
    }
    .box:last-child {
      margin-bottom: 0;
    }
  }
  .chart {
    max-width: 800px;
    padding-left: 50px;
    border: 1px solid #000;
    padding-right: 10px;
    .req-para,
    .no-req-para {
      font: 16px/16px $font-medium;
      color: $tc-bk;
    }
    .req-para {
      position: absolute;
      left: -35px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
    }
    .no-req-para {
      text-align: center;
      padding-bottom: 17px;
    }
  }
  .apexcharts-toolbar {
    display: none;
  }
  .apexcharts-tooltip {
    display: none !important;
  }
  .apexcharts-series path {
    filter: none;
    fill: #ff9900;
  }
  tspan {
    font: 14px/16px $font-medium;
    color: $tc-bk;
  }
  .apexcharts-data-labels text {
    display: none;
  }
  line {
    stroke: #a1a1a1;
  }
  .apexcharts-yaxis-texts-g {
    transform: translate(-34px, 5px);
  }
  .events-blk {
    .events-list {
      ul {
        li {
          background: #555;
          margin-bottom: 10px;
          .lft {
            display: inline-block;
            vertical-align: middle;
            width: 20%;
            border: 1px solid #555;
            h4 {
              text-align: center;
              strong {
                font: 18px/20px $font-bold;
                padding: 5px;
                display: block;
                color: $tc-wt;
                background: #ff9900;
                border-bottom: 1px solid #e6ba86;
              }
              span {
                font: 24px/24px $font-medium;
                color: $tc-bk;
                display: block;
                background: #f6b26b;
                padding: 10px;
              }
            }
          }
          .rgt {
            display: inline-block;
            vertical-align: middle;
            width: 80%;
            p {
              font: 16px/20px $font-medium;
              color: $tc-wt;
              text-align: center;
              padding: 10px;
            }
          }
        }
      }
    }
  }
  .datepicker-blk {
    .DayPicker {
      width: 100%;
    }
    .DayPicker-Month {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
    .DayPicker-wrapper {
      padding-bottom: 0;
    }
    .DayPicker-Caption {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      background: #eee;
      padding: 15px 10px;
      margin-bottom: 0;
      border: 1px solid #555;
    }
    .DayPicker-Weekday {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      border: 1px solid #555;
      border-top: 0;
    }
    .DayPicker-Day {
      font: 16px/20px $font-regular;
      color: $tc-bk;
      border: 1px solid #555;
    }
    .DayPicker-Day--selected {
      background: #ff9900;
      font-family: $font-medium;
      border-radius: 0;
    }
    .DayPicker-Day--selected:hover {
      background: #ff9900;
    }
    .DayPicker-Day--outside {
      background: transparent;
    }
    .DayPicker-NavButton {
      top: 14px;
      right: 0.5em;
    }
    .DayPicker-Weekdays {
      background: #eee;
      padding: 10px;
      margin-top: 0;
    }
    .DayPicker-WeekdaysRow {
      border: 1px solid #555;
      border-top: 0;
    }
  }
}

// Calls component > start
.table-blk {
  .nav-tabs .nav-item {
    font-size: 0;
  }
  .nav-tabs .nav-item .nav-link {
    font: 16px/20px $font-medium;
    color: $tc-gray;
    background: transparent;
    width: 90px;
    border-top: 1px solid #777777;
    border-left: 1px solid #777777;
    border-bottom: 0;
    border-radius: 0;
  }
  .nav-tabs .nav-item:last-child .nav-link {
    border-right: 1px solid #777777;
  }
  .nav-tabs .nav-item .nav-link:hover,
  .nav-tabs .nav-item .nav-link:focus {
    border-bottom: 0;
  }
  .nav-tabs .nav-item .nav-link.active {
    background: #777777;
    color: $tc-wt;
    border-radius: 0;
    border-color: #777777;
  }
  table tbody {
    border-left: 1px solid #5d5d5d;
    border-right: 1px solid #5d5d5d;
    border-bottom: 1px solid #5d5d5d;
  }
  table thead {
    background: $thead-bg;
    border-right: 1px solid #5d5d5d;
  }
  table thead tr th {
    color: $tc-wt;
    padding: 12px;
    font: 16px/20px $font-medium;
    background: $thead-bg;
  }
  table thead tr th .th-title{
display: flex;
align-items: center;
  }
  table tbody tr td {
    color: $tc-gray;
    padding: 12px;
    font: 16px/20px $font-regular;
  }
  table tbody tr td a {
    display: block;
    text-decoration: underline !important;
    color: $tc-bk;
    padding: 0 0 5px 0;
  }
  .pagination {
    display: inline-block;
  }
  
  .pagination div,button {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    border: none;

  }
  
  .pagination div.active {
    background-color: #4CAF50;
    color: white;
  }
  
  .pagination div:hover:not(.active) {background-color: #ddd; cursor: pointer;}
}

.calls-dropdown {
  .btn {
    width: 140px;
    background: $thead-bg;
    border-radius: 5px;
    position: relative;
    font: 16px/20px $font-medium;
    color: $tc-wt;
  }
  .btn.show {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dropdown-toggle::after {
    right: 20px;
    position: absolute;
    top: 15px;
  }
  .dropdown-menu {
    min-width: 140px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #5d5d5d;
  }
  .dropdown-menu li a {
    font: 16px/20px $font-medium;
    color: $tc-bk;
  }
  .dropdown-menu li a:hover {
    background: $thead-bg;
    color: $tc-wt;
  }
  .btn-secondary:focus {
    box-shadow: none;
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
  .btn-secondary:active:focus {
    box-shadow: none;
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
}
.new-call-btn {
  width: 130px;
  float: right;
}
.new-call-btn a {
  display: block;
  background: $thead-bg;
  border-radius: 5px;
  font: 16px/20px $font-medium;
  color: $tc-wt;
  text-align: center;
  padding: 10px;
}
.new-call-btn a:hover {
  background: $thead-bg;
  color: $tc-wt;
}
// Calls component > end

// New call component > start
.newcall-lft-blk {
  padding-right: 0px;
  .list-blk {
    .form-group {
      max-width: 392px;
      .form-control {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        padding: 12px 20px;
        border-radius: 15px;
        border: 1px solid #5d5d5d;
      }
      .form-control::placeholder {
        font: 13px/18px $font-regular;
      }
      .form-control:focus {
        box-shadow: none;
      }
      ul {
        margin: 10px 20px 0 20px;
        padding: 0;
        li {
          cursor: pointer;
          font: 16px/20px $font-medium;
          color: $tc-bk;
          span {
            font: 16px/20px $font-medium;
            color: $tc-bk;
            padding: 4px 0;
            display: inline-block;
            width: 50%;
            z-index: -1;
            position: relative;
          }
          span:last-child {
            text-align: right;
          }
        }
      }
    }
    .which-scrip-show {
      position: relative;
      // display: none;
      .append-scrip {
        font: 16px/20px "Graphik-Medium";
        color: $tc-wt;
        border-radius: 15px;
        background: $primary-bg;
        padding: 12px 20px;
        margin: 0;
        span {
          padding-right: 30px;
          width: 50%;
          display: inline-block;
        }
        position: relative;
        span:first-child {
          padding-right: 0;
        }
      }
      .append-scrip span:last-child {
        text-align: right;
      }
      p.edit-btn {
        position: absolute;
        top: 50%;
        right: 20px;
        background: url("../images/edit-btn.png") no-repeat;
        width: 13px;
        height: 13px;
        cursor: pointer;
        margin: 0;
        transform: translate(0, -50%);
      }
    }
    .css-2b097c-container {
      max-width: 250px;
    }
    .css-yk16xz-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
    }
    .css-1pahdxg-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
      box-shadow: none;
    }
    .css-yk16xz-control:hover {
      border-color: #5d5d5d;
    }
    .css-1pahdxg-control:hover {
      border-color: #5d5d5d;
    }
    .css-1wa3eu0-placeholder,
    .css-tlfecz-indicatorContainer {
      color: $tc-bk;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-26l3qy-menu {
      margin: 0;
      border-radius: 0;
      border: 1px solid #5d5d5d;
      border-top: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: none;
      margin-top: -10px;
    }
    .css-4ljt47-MenuList {
      padding-top: 10px;
      padding-bottom: 0;
    }
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
      font: 16px/20px "Graphik-Medium";
    }
    .css-4ljt47-MenuList div {
      padding: 8px 20px;
    }
    .css-4ljt47-MenuList div:first-child {
      border-top: 1px solid #5d5d5d;
    }
    .css-4ljt47-MenuList div:hover {
      background: #5d5d5d;
      color: #fff;
    }
    .css-1n7v3ny-option {
      background: #5d5d5d;
      color: #fff;
    }
    .css-yt9ioa-option:hover {
      background: #5d5d5d;
    }
    .css-9gakcf-option {
      background: #5d5d5d;
    }
    .css-4ljt47-MenuList div:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .list-blk.options-gray .which-scrip-show {
    max-width: 250px;
  }
}
.px-1 {
  padding: 0 5px 10px 5px;
}
.call-field-header {
  font: 14px/20px "Graphik-Medium";
  margin: 7px;
}

.newcall-rgt-blk {
  padding-left: 20px;
  border-left: 1px solid #000;
  height: 100%;
  .form-blk {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    // max-width: 300px;
    position: relative;
    padding-right: 25px;
    .form-group {
      display: inline-block;
      vertical-align: top;
      position: relative;
      .form-control {
        border: 1px solid #5d5d5d;
        font: 16px/20px "Graphik-Medium";
        border-radius: 12px;
        padding: 12px;
      }
      .form-control:focus {
        box-shadow: none;
      }
      .form-control::placeholder {
        color: $tc-gray;
        font: 15px/20px $font-regular;
      }
      .target-delete-btn {
        position: absolute;
        right: 20px;
        top: 50%;
        background: url("../images/close-icon.png") no-repeat;
        width: 21px;
        height: 21px;
        transform: translate(0, -50%);
        cursor: pointer;
      }
    }
    .plus-btn {
      position: absolute;
      bottom: 20px;
      right: 0;
      background: url("../images/plus-btn.png") no-repeat;
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
    .plus-btn.avail {
      bottom: 0;
    }
  }
  .btn-group {
    .btn {
      font: 16px/20px "Graphik-Medium";
      background: $p-btn-bg;
      color: $p-btn-tc;
      padding: 12px;
    }
  }
}
.list-blk {
  padding-top: 30px;
  h2 {
    font: 16px/20px $font-medium;
    color: $tc-bk;
    margin-bottom: 8px;
  }
  .list-group {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    .list-group-item {
      span {
        color: $tc-bk;
        background: transparent;
        min-width: 120px;
        border: 1px solid #5d5d5d;
        font: 16px/20px $font-medium;
        border-radius: 12px;
        padding: 12px;
        cursor: pointer;
      }
      span:hover {
        // background: $primary-bg;
        // color: $tc-wt;
        // transition: all 0.5s;
      }
    }
    .list-group-item.active {
      background-color: transparent;
      z-index: inherit;
    }
    .list-group-item.active span {
      background: $primary-bg;
      color: $tc-wt;
    }
    .list-group-item.active
      .react-datepicker__input-container
      .example-custom-input {
      background: $primary-bg;
      color: $tc-wt;
    }
    &.call-list {
      display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    }
  }
  .dropdown .btn {
    width: 160px;
    background: transparent;
    border: 1px solid #5d5d5d;
    border-radius: 5px;
    position: relative;
    font: 16px/20px $font-medium;
    color: $tc-bk;
    padding: 12px 20px;
    border-radius: 12px;
  }
  .dropdown-toggle::after {
    right: 20px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .btn-secondary:focus {
    box-shadow: none;
  }
  .btn-secondary:active:focus {
    box-shadow: none;
  }
  .dropdown-menu {
    min-width: 160px;
    border: 1px solid #5d5d5d;
  }
  .dropdown-menu li:last-child,
  .dropdown-menu li:last-child a {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .dropdown-menu.show {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .btn.show {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .dropdown-menu li a {
    font: 16px/20px $font-medium;
    color: $tc-bk;
    padding-left: 20px;
    padding-right: 10px;
  }
  .dropdown-menu li a:hover {
    background: $thead-bg;
    color: $tc-wt;
  }
}
// New call component > end

// hedgeometer component > start
.admin-hedgeometer {
  .common-para {
    position: relative;
    font-family: $font-medium;
    .plus-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      background: url("../images/plus-btn.png") no-repeat;
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
  }
  .lft-blk {
    ul {
      margin: 0 -20px;
      li {
        padding: 0 20px 20px 20px;
        .box {
          min-height: 120px;
          border: 1px dashed #555555;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          .edit-btn {
            position: absolute;
            top: 10px;
            right: -15px;
            // background: url("../images/edit-btn-blk.png") no-repeat;
            width: 15px;
            height: 15px;
            cursor: pointer;
            margin: 0;
            transition: all 0.3s;
            color: #000;
          }
        }
        .box:hover .edit-btn {
          right: 15px;
        }
        .box.active {
          background: #aaaaaa;
          color: #555555;
          border: 2px solid #000;
        }
      }
      li.active .box {
        background: $primary-bg;
      }
      li.active .edit-btn {
        right: 15px;
        // background: url("../images/edit-btn.png") no-repeat;
        color: #fff;
      }

      li.active .box h2 {
        color: $tc-wt;
      }
      li.active .box.active {
        pointer-events: none;
      }
    }
    h2 {
      font: 18px/22px $font-bold;
      color: $tc-gray;
      text-transform: uppercase;
      margin: 0;
      padding: 0 10px;
      text-align: center;
      // span{font:14px/18px $font-regular;text-transform: initial;padding-top: 10px;}
      span {
        font: 18px/18px $font-medium !important;
        text-transform: initial;
        padding-top: 10px;
      }
      strong {
        font: 14px/18px $font-regular;
      }
    }
    .btn-group {
      button {
        font: 16px/20px $font-medium;
        background: #000;
        color: $p-btn-tc;
        padding: 12px;
        width: 100%;
      }
      button:focus {
        box-shadow: none;
      }
      button.disabled {
        background: $p-btn-disable-bg;
        color: $p-btn-disable-tc;
      }
    }
  }

  .rgt-blk {
    .table-blk {
      table thead {
        background: transparent;
        border: 1px solid #5d5d5d;
        border-bottom: 0;
      }
      table tr th,
      table tr td {
        text-transform: uppercase;
        color: $tc-gray;
        font: 16px/20px $font-bold;
      }
      table tr td {
        font: 16px/20px $font-regular;
      }
    }
  }
  .status-popup-blk {
    .modal-header {
      border-bottom: 0;
      padding: 20px 20px 10px 20px;
      h5 {
        font: 20px/20px $font-medium;
        color: $tc-bk;
      }
      .btn-close:focus {
        box-shadow: none;
      }
    }
    .modal-body {
      padding: 10px 20px;
      .form-group {
        .form-control {
          border: 1px solid #5d5d5d;
          font: 16px/20px "Graphik-Medium";
          border-radius: 12px;
          padding: 12px;
          color: $tc-bk;
        }
        .form-control:focus {
          box-shadow: none;
        }
        .form-control::placeholder {
          color: $tc-bk;
        }
      }
    }
    .modal-footer {
      padding-left: 20px;
      padding-top: 0;
      border-top: 0;
      display: block;
      text-align: left;
      button {
        display: inline-block;
        font: 16px/16px $font-medium;
        color: $tc-wt;
        background: #2b2b2b;
        padding: 10px;
        text-transform: uppercase;
        min-width: 100px;
        border-color: #2b2b2b;
      }
    }
  }
  #apexchartsae6gtv2gl {
    padding-bottom: 30px;
  }
  .apexcharts-datalabel-label {
    font: 18px/18px $font-regular;
    fill: $tc-bk;
    text-transform: uppercase;
  }
  .apexcharts-datalabel-value {
    font: 34px/34px $font-bold;
    color: $tc-bk;
    display: none;
  }

  #apexcharts-radialbarTrack-0 {
    stroke: #4a4a4a;
  }
  .apexcharts-radialbar-slice-0 {
    stroke: #f67452;
  }
}
// hedgeometer component > end

// Bulish price component > start
.price-volume-page {
  height: 100%;
  padding-top: 30px;
  padding-left: 40px;
  border-left: 1px solid #000;
  .pv-inner {
    border: 1px solid #5d5d5d;
    padding: 20px;
    border-radius: 12px;
    position: relative;
    background: #e9e9e9;
    h2 {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      margin-bottom: 15px;
    }
    .list-group {
      .list-group-item {
        border: 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: #e9e9e9;
        span {
          font: 15px/20px $font-regular;
          color: $tc-bk;
        }
        span.price {
          font: 18px/20px $font-medium;
          color: $tc-bk;
          display: block;
        }
      }
    }
    .price-exit-btn p {
      position: absolute;
      top: 20px;
      right: 20px;
      background: url("../images/edit-btn-blk.png") no-repeat;
      width: 14px;
      height: 15px;
      cursor: pointer;
      margin: 0;
    }
  }
}
// Bulish price component > end

// SaveProceedLeft > start
.admin-saveProceedLeft {
  padding-top: 30px;
  padding-right: 40px;
  // border-right: 1px solid #000;
  .pv-inner {
    position: relative;
    h2 {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      margin-bottom: 15px;
    }
    .list-group {
      .list-group-item {
        border: 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        span {
          font: 15px/20px $font-regular;
          color: $tc-bk;
        }
        span.price {
          font: 18px/20px $font-medium;
          color: $tc-bk;
          display: block;
          text-transform: capitalize;
        }
      }
    }
    .price-exit-btn p {
      position: absolute;
      top: 20px;
      right: 20px;
      background: url("../images/edit-btn-blk.png") no-repeat;
      width: 14px;
      height: 15px;
      cursor: pointer;
      margin: 0;
    }
  }
}
// SaveProceedLeft > end

// SaveProceedRight > start
.admin-saveProceedRight {
  padding-top: 30px;
  padding-left: 40px;
  padding-bottom: 80px;
  height: 100%;
  border-left: 1px solid #000;
  .select-blk {
    h2 {
      font: 16px/20px "Graphik-Medium";
      color: #000;
      margin-bottom: 8px;
    }
    .css-yk16xz-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
    }
    .css-1pahdxg-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
      box-shadow: none;
    }
    .css-yk16xz-control:hover {
      border-color: #5d5d5d;
    }
    .css-1pahdxg-control:hover {
      border-color: #5d5d5d;
    }
    .css-1wa3eu0-placeholder,
    .css-tlfecz-indicatorContainer {
      color: $tc-bk;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-26l3qy-menu {
      margin: 0;
      border-radius: 0;
      border: 1px solid #5d5d5d;
      border-top: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: none;
      margin-top: -10px;
    }
    .css-4ljt47-MenuList {
      padding-top: 10px;
      padding-bottom: 0;
    }
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
      font: 16px/20px "Graphik-Medium";
    }
    .css-4ljt47-MenuList div {
      padding: 8px 20px;
    }
    .css-4ljt47-MenuList div:first-child {
      border-top: 1px solid #5d5d5d;
    }
    .css-4ljt47-MenuList div:hover {
      background: #5d5d5d;
      color: #fff;
    }
    .css-1n7v3ny-option {
      background: #5d5d5d;
      color: #fff;
    }
    .css-yt9ioa-option:hover {
      background: #5d5d5d;
    }
    .css-9gakcf-option {
      background: #5d5d5d;
    }
    // .css-yk16xz-control{border-bottom-left-radius:0;border-bottom-right-radius:0;}
    .css-4ljt47-MenuList div:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .list-blk {
    .react-datepicker__input-container {
      color: #000;
      background: transparent;
      min-width: 120px;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
    }
    .react-datepicker__input-container input {
      border: 0;
      padding: 12px;
    }
    .react-datepicker__header {
      background-color: #e6e6e6;
      border: 0;
    }
    .react-datepicker__day-names {
      background-color: #ebebeb;
    }
    .react-datepicker__month {
      background-color: #f2f2f2;
      margin: 0;
      padding: 5px;
    }
    .react-datepicker__current-month {
      padding-bottom: 8px;
    }
    .react-datepicker__day--outside-month {
      color: #aaaaaa;
      pointer-events: none;
    }
    .react-datepicker-time__caption {
      display: none;
    }
    .react-datepicker__input-time-container {
      margin: 0;
      padding: 10px 0;
    }
    .react-datepicker__input-time-container
      .react-datepicker-time__input-container {
      width: 100%;
      text-align: center;
    }
    .react-datepicker-time__input input {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      margin: 0;
      border: 0;
      border-radius: 0;
    }
    .react-datepicker__day--selected {
      background: #5d5d5d;
      color: #fff;
    }
    .example-custom-input {
      color: #000;
      background: transparent;
      min-width: 120px;
      border: 0;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 12px;
      transition: all 0.5s;
      border: 1px solid #5d5d5d;
    }
    .example-custom-input:hover {
      background: #5d5d5d;
      color: #fff;
    }
    .custom-datepicker strong {
      font: 16px/20px $font-regular;
      color: $tc-bk;
      padding-left: 10px;
    }
    .form-check-inline {
      margin-right: 30px;
    }
    .form-check {
      .form-check-input {
        border-color: $tc-bk;
        cursor: pointer;
      }
      .form-check-label {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        cursor: pointer;
      }
      .form-check-input:checked {
        background-color: #555555;
        border-color: #555555;
        background-image: url("../images/check-icon.png");
      }
      .form-check-input:active {
        filter: brightness(100%);
      }
      .form-check-input:focus {
        box-shadow: none;
      }
    }
    .form-group {
      padding-top: 25px;
      label {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        padding-bottom: 5px;
      }
      .form-control {
        border: 1px solid #5d5d5d;
        font: 16px/20px $font-medium;
        padding: 12px;
        border-radius: 0;
      }
      .form-control:focus {
        box-shadow: none;
      }
      .form-control::placeholder {
        color: $tc-bk;
      }
      textarea {
        min-height: 100px;
        resize: none;
      }
      .submit-btn {
        padding-top: 15px;
        button {
          font: 16px/20px $font-medium;
          color: $tc-wt;
          background: #555;
          padding: 10px 10px;
          min-width: 120px;
          border: 0;
        }
      }
    }
    .form-group:first-child {
      padding-top: 0;
    }
    .accordion-blk {
      padding-bottom: 15px;
      .accordion-button {
        font: 18px/20px $font-medium;
        color: $tc-bk;
        border: 0;
        span {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          padding-right: 20px;
        }
      }
      .accordion-header {
        border: 1px solid #5d5d5d;
        border-bottom: 0;
        border-radius: 0;
        margin-bottom: 0;
      }
      .accordion-header:last-child {
        border-top: 1px solid #5d5d5d;
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-button:focus {
        border-color: #5d5d5d;
        box-shadow: none;
      }
      .accordion-button:not(.collapsed) {
        background-color: #5d5d5d;
        color: #fff;
      }
      .accordion-item:first-of-type .accordion-button {
        border-radius: 0;
      }
      .accordion-item:last-of-type .accordion-button.collapsed {
        border-radius: 0;
        border-top: 0;
      }
      .accordion-item:last-child .accordion-header {
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-item:last-child .accordion-collapse {
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-collapse {
        border: 1px solid #5d5d5d;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
      }
      // .accordion-body:last-child{border-bottom:1px solid #5d5d5d;}
      // .accordion-collapse.show{border-bottom: 0;}
      // .accordion-collapse.show:last-child{border-bottom:0;}
      .accordion-button:not(.collapsed)::after {
        background-image: url("../images/down-arrow.svg");
        transform: rotateX(180deg);
      }
      .accordion-body p {
        font: 16px/24px $font-regular;
        color: $tc-bk;
        padding-top: 10px;
      }
      .add-trade-logic {
        p {
          font: 18px/20px $font-medium;
          color: $tc-bk;
          text-decoration: underline;
          text-align: center;
          padding-top: 25px;
        }
      }
    }
  }
  .file-upload-blk {
    label {
      font: 16px/20px $font-medium;
      color: #000;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: middle;
      text-align: center;
      text-decoration: underline;
    }
    input {
      height: 0;
      overflow: hidden;
      width: 0;
    }
  }
  .list-blk.capital-all {
    .form-group {
      .form-control {
        border-radius: 15px;
      }
    }
  }
}
// SaveProceedRight > end

// optionRightComponent > start
.option-rgt-blk {
  height: 100%;
  border-left: 1px solid #000;
  padding-left: 20px;
  .css-2b097c-container {
    max-width: 250px;
  }
  .css-yk16xz-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px "Graphik-Medium";
    border-radius: 12px;
    padding: 4px 10px;
  }
  .css-1pahdxg-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px "Graphik-Medium";
    border-radius: 12px;
    padding: 4px 10px;
    box-shadow: none;
  }
  .css-yk16xz-control:hover {
    border-color: #5d5d5d;
  }
  .css-1pahdxg-control:hover {
    border-color: #5d5d5d;
  }
  .css-1wa3eu0-placeholder,
  .css-tlfecz-indicatorContainer {
    color: $tc-bk;
    padding-right: 0;
  }
  .css-1gtu0rj-indicatorContainer {
    padding-right: 0;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-26l3qy-menu {
    margin: 0;
    border-radius: 0;
    border: 1px solid #5d5d5d;
    border-top: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    margin-top: -10px;
  }
  .css-4ljt47-MenuList {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .css-yt9ioa-option,
  .css-1n7v3ny-option,
  .css-9gakcf-option {
    font: 16px/20px "Graphik-Medium";
  }
  .css-4ljt47-MenuList div {
    padding: 8px 20px;
  }
  .css-4ljt47-MenuList div:first-child {
    border-top: 1px solid #5d5d5d;
  }
  .css-4ljt47-MenuList div:hover {
    background: #5d5d5d;
    color: #fff;
  }
  .css-1n7v3ny-option {
    background: #5d5d5d;
    color: #fff;
  }
  .css-yt9ioa-option:hover {
    background: #5d5d5d;
  }
  .css-9gakcf-option {
    background: #5d5d5d;
  }
  .css-4ljt47-MenuList div:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .which-scrip-show {
    max-width: 100px;
    position: relative;
    // display: none;
    .append-scrip {
      font: 16px/20px "Graphik-Medium";
      color: $tc-wt;
      border-radius: 15px;
      background: $primary-bg;
      padding: 12px 20px;
      margin: 0;
      span {
        padding-right: 40px;
        width: 50%;
        display: inline-block;
      }
      position: relative;
    }
    .append-scrip span:last-child {
      text-align: right;
    }
    p.edit-btn {
      position: absolute;
      top: 50%;
      right: 20px;
      background: url("../images/edit-btn.png") no-repeat;
      width: 13px;
      height: 13px;
      cursor: pointer;
      margin: 0;
      transform: translate(0, -50%);
    }
  }
  .leg-blk {
    padding-bottom: 30px;
    padding-top: 30px;
    .form-blk {
      padding: 15px;
      position: relative;
      border-radius: 10px;
      margin-bottom: 20px;
      h2 {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        margin-bottom: 8px;
      }
      .form-group {
        display: inline-block;
        vertical-align: middle;
        width: 16%;
        input {
          color: $tc-gray;
          background: transparent;
          border: 1px solid #5d5d5d;
          font: 15px/20px $font-medium;
          border-radius: 12px;
          padding: 12px;
        }
        input::placeholder {
          color: $tc-gray;
          font: 13px/20px $font-regular;
        }
        .css-1wa3eu0-placeholder {
          color: black !important;
          font: 14px/20px $font-regular;
        }
        input:focus {
          box-shadow: none;
        }
      }
      .form-group.w-18 {
        width: 16%;
      }
      .form-group.w-6 {
        width: 4%;
      }
      .close-btn {
        background: url("../images/close-icon.png") no-repeat;
        width: 21px;
        height: 21px;
        cursor: pointer;
        float: right;
        margin-top: 23px;
      }
    }
    .btn-group {
      padding-top: 40px;
      .btn {
        font: 16px/20px $font-medium;
        background: $primary-bg;
        color: $tc-wt;
        padding: 12px;
      }
    }
  }
  .add-legs-blk {
    position: relative;
    padding-top: 30px;
    p {
      font: 16px/20px $font-medium;
      color: $tc-bk;
    }
    .plus-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      background: url("../images/plus-btn.png") no-repeat;
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
  }
}
.call-participat-blk {
  padding-bottom: 70px;
  .total-calls-blk {
    border: 1px solid #5d5d5d;
    padding: 20px;
    border-radius: 12px;
    background: #e9e9e9;
    // max-width: 330px;
    .calls {
      padding-top: 20px;
      h3 {
        color: $tc-gray;
        font: 15px/20px $font-regular;
      }
      p {
        color: $tc-bk;
        font: 18px/18px $font-medium;
        margin: 5px -20px 0 -20px;
        position: relative;
        span {
          padding: 0 20px;
          width: 25%;
          display: inline-block;
          vertical-align: middle;
        }
        span.call-editBtn {
          position: absolute;
          top: 50%;
          right: 0;
          background: url("../images/edit-btn-blk.png") no-repeat;
          width: 14px;
          height: 15px;
          cursor: pointer;
          margin: 0;
          -webkit-transform: translate(0, -50%);
          -moz-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          -o-transform: translate(0, -50%);
          transform: translate(0, -50%);
        }
      }
    }
    .calls:first-child {
      padding-top: 0;
    }
  }
  .css-1wa3eu0-placeholder {
    font: 15px/20px $font-regular;
    color: $tc-gray;
  }
}

.participt-blk {
  // max-width: 330px;
  margin: 0 -10px -10px -10px;
  .form-group {
    display: inline-block;
    vertical-align: top;
    width: 33.33333333333333%;
    padding: 0 5px 10px 5px;
    input {
      color: $tc-bk;
      background: transparent;
      border: 1px solid #5d5d5d;
      font: 15px/20px $font-medium;
      border-radius: 12px;
      padding: 12px;
    }
    input::placeholder {
      font: 15px/20px $font-regular;
      color: $tc-gray;
    }
    input:focus {
      box-shadow: none;
    }
    .form-group.w-50 {
      width: 50%;
    }
  }
}
.par-data-blk {
  max-width: 350px;
  padding-top: 30px;
  h2 {
    font: 16px/20px $font-medium;
    color: $tc-bk;
    margin-bottom: 8px;
  }
  ul {
    li {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      padding-bottom: 15px;
    }
    li span:first-child {
      font: 15px/20px $font-regular;
      color: $tc-bk;
    }
    li span:last-child {
      font: 18px/20px $font-medium;
      color: $tc-bk;
      display: block;
    }
  }
}
// optionRightComponent > end

// bulishSaveProceedLeft > start
.admin-bulishSaveProceedLeft {
  padding-top: 30px;
  .pv-inner {
    position: relative;
    h2 {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      margin-bottom: 15px;
    }
    .list-group {
      display: block;
      .list-group-item {
        display: inline-block;
        width: 50%;
        border: 0;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        span {
          font: 15px/20px $font-regular;
          color: $tc-bk;
        }
        span.price {
          font: 18px/20px $font-medium;
          color: $tc-bk;
          display: block;
          text-transform: capitalize;
        }
      }
    }
    .list-group.w-33 {
      .list-group-item {
        width: 33.33333333333333%;
      }
    }
  }
  .total-calls-blk {
    border: 1px solid #5d5d5d;
    padding: 15px;
    border-radius: 12px;
    background: #e9e9e9;
    // max-width: 330px;
    .calls {
      padding-top: 20px;
      h3 {
        color: $tc-gray;
        font: 15px/20px $font-regular;
      }
      p {
        color: $tc-bk;
        font: 18px/18px $font-medium;
        margin: 5px -15px 0 -15px;
        position: relative;
        span:first-child {
          // width: 26%;
        }
        span {
          padding: 0 15px;
          width: 19.5%;
          display: inline-block;
          vertical-align: middle;
        }
        span.call-editBtn {
          position: absolute;
          top: 50%;
          right: 0;
          background: url("../images/edit-btn-blk.png") no-repeat;
          width: 14px;
          height: 15px;
          cursor: pointer;
          margin: 0;
          -webkit-transform: translate(0, -50%);
          -moz-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          -o-transform: translate(0, -50%);
          transform: translate(0, -50%);
        }
      }
    }
    .calls:first-child {
      padding-top: 0;
    }
  }
}
// bulishSaveProceedLeft > end

// bulishSaveProceedRight > start
.admin-bulishSaveProceedRight {
  padding-left: 40px;
  height: 100%;
  border-left: 1px solid #000;
  .select-blk {
    padding-top: 30px;
    h2 {
      font: 16px/20px "Graphik-Medium";
      color: #000;
      margin-bottom: 8px;
    }
    .css-yk16xz-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
    }
    .css-1pahdxg-control {
      border: 1px solid #5d5d5d;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 4px 10px;
      box-shadow: none;
    }
    .css-yk16xz-control:hover {
      border-color: #5d5d5d;
    }
    .css-1pahdxg-control:hover {
      border-color: #5d5d5d;
    }
    .css-1wa3eu0-placeholder,
    .css-tlfecz-indicatorContainer {
      color: $tc-bk;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-26l3qy-menu {
      margin: 0;
      border-radius: 0;
      border: 1px solid #5d5d5d;
      border-top: 0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      box-shadow: none;
      margin-top: -10px;
    }
    .css-4ljt47-MenuList {
      padding-top: 10px;
      padding-bottom: 0;
    }
    .css-yt9ioa-option,
    .css-1n7v3ny-option {
      font: 16px/20px "Graphik-Medium";
    }
    .css-4ljt47-MenuList div {
      padding: 8px 20px;
    }
    .css-4ljt47-MenuList div:first-child {
      border-top: 1px solid #5d5d5d;
    }
    .css-4ljt47-MenuList div:hover {
      background: #5d5d5d;
      color: #fff;
    }
    .css-1n7v3ny-option {
      background: #5d5d5d;
      color: #fff;
    }
    .css-yt9ioa-option:hover {
      background: #5d5d5d;
    }
    .css-9gakcf-option {
      background: #5d5d5d;
    }
    // .css-yk16xz-control{border-bottom-left-radius:0;border-bottom-right-radius:0;}
    .css-4ljt47-MenuList div:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
  .list-blk {
    .react-datepicker__input-container {
      color: #000;
      background: transparent;
      min-width: 120px;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
    }
    .react-datepicker__input-container input {
      border: 0;
      padding: 12px;
    }
    .react-datepicker__header {
      background-color: #e6e6e6;
      border: 0;
    }
    .react-datepicker__day-names {
      background-color: #ebebeb;
    }
    .react-datepicker__month {
      background-color: #f2f2f2;
      margin: 0;
      padding: 5px;
    }
    .react-datepicker__current-month {
      padding-bottom: 8px;
    }
    .react-datepicker__day--outside-month {
      color: #aaaaaa;
      pointer-events: none;
    }
    .react-datepicker-time__caption {
      display: none;
    }
    .react-datepicker__input-time-container {
      margin: 0;
      padding: 10px 0;
    }
    .react-datepicker__input-time-container
      .react-datepicker-time__input-container {
      width: 100%;
      text-align: center;
    }
    .react-datepicker-time__input input {
      font: 16px/20px $font-medium;
      color: $tc-bk;
      margin: 0;
      border: 0;
      border-radius: 0;
    }
    .react-datepicker__day--selected {
      background: #5d5d5d;
      color: #fff;
    }
    .example-custom-input {
      color: #000;
      background: transparent;
      min-width: 120px;
      border: 0;
      font: 16px/20px "Graphik-Medium";
      border-radius: 12px;
      padding: 12px;
      transition: all 0.5s;
      border: 1px solid #5d5d5d;
    }
    .example-custom-input:hover {
      background: #5d5d5d;
      color: #fff;
    }
    .custom-datepicker strong {
      font: 16px/20px $font-regular;
      color: $tc-bk;
      padding-left: 10px;
    }
    .form-check-inline {
      margin-right: 30px;
    }
    .form-check {
      .form-check-input {
        border-color: $tc-bk;
        cursor: pointer;
      }
      .form-check-label {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        cursor: pointer;
      }
      .form-check-input:checked {
        background-color: #555555;
        border-color: #555555;
        background-image: url("../images/check-icon.png");
      }
      .form-check-input:active {
        filter: brightness(100%);
      }
      .form-check-input:focus {
        box-shadow: none;
      }
    }
    .form-group {
      padding-top: 20px;
      label {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        padding-bottom: 5px;
      }
      .form-control {
        border: 1px solid #5d5d5d;
        font: 16px/20px $font-medium;
        padding: 12px;
        border-radius: 0;
      }
      .form-control:focus {
        box-shadow: none;
      }
      .form-control::placeholder {
        color: $tc-bk;
      }
      textarea {
        min-height: 100px;
        resize: none;
      }
      .submit-btn {
        padding-top: 15px;
        button {
          font: 16px/20px $font-medium;
          color: $tc-wt;
          background: #555;
          padding: 10px 10px;
          min-width: 120px;
          border: 0;
        }
      }
    }
    .form-group:first-child {
      padding-top: 0;
    }
    .accordion-blk {
      padding-bottom: 15px;
      .accordion-button {
        font: 18px/20px $font-medium;
        color: $tc-bk;
        border: 0;
        span {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          padding-right: 20px;
        }
      }
      .accordion-header {
        border: 1px solid #5d5d5d;
        border-bottom: 0;
        border-radius: 0;
        margin-bottom: 0;
      }
      .accordion-header:last-child {
        border-top: 1px solid #5d5d5d;
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-button:focus {
        border-color: #5d5d5d;
        box-shadow: none;
      }
      .accordion-button:not(.collapsed) {
        background-color: #5d5d5d;
        color: #fff;
      }
      .accordion-item:first-of-type .accordion-button {
        border-radius: 0;
      }
      .accordion-item:last-of-type .accordion-button.collapsed {
        border-radius: 0;
        border-top: 0;
      }
      .accordion-item:last-child .accordion-header {
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-item:last-child .accordion-collapse {
        border-bottom: 1px solid #5d5d5d;
      }
      .accordion-collapse {
        border: 1px solid #5d5d5d;
        border-radius: 0;
        border-top: 0;
        border-bottom: 0;
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url("../images/down-arrow.svg");
        transform: rotateX(180deg);
      }
      .accordion-body p {
        font: 16px/24px $font-regular;
        color: $tc-bk;
        padding-top: 10px;
      }
      .add-trade-logic {
        p {
          font: 18px/20px $font-medium;
          color: $tc-bk;
          text-decoration: underline;
          text-align: center;
          padding-top: 25px;
        }
      }
    }
  }
  .file-upload-blk {
    label {
      font: 16px/20px $font-medium;
      color: $tc-bk !important;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 10px;
      vertical-align: middle;
      text-align: center;
      text-decoration: underline;
    }
    input {
      height: 0;
      overflow: hidden;
      width: 0;
    }
  }
  .lot-size-blk {
    .form-group {
      label {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        margin-bottom: 8px;
      }
      .form-control {
        color: $tc-bk;
        background: transparent;
        border: 1px solid #5d5d5d;
        font: 15px/20px $font-medium;
        border-radius: 12px;
        padding: 12px;
      }
      .form-control:focus {
        box-shadow: none;
      }
    }
  }
}
// bulishSaveProceedRight > end

// Call details > start
.admin-call-details {
  position: relative;
  .call-inner {
    margin-left: 8px;
  }
  .subhead {
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 30px;
    h2 {
      font: 18px/22px $font-medium;
      color: $tc-bk;
    }
    ul {
      margin: 0 -10px;
      padding-top: 10px;
      li {
        font: 18px/18px $font-medium;
        color: $tc-bk;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        position: relative;
      }
      li:before {
        content: "";
        position: absolute;
        right: 0;
        width: 1px;
        height: 100%;
        background: #000;
      }
      li:last-child:before {
        content: none;
      }
    }
  }
  .box-blk {
    padding-bottom: 30px;
    border-left: 1px solid #000;
    .box {
      // padding: 10px 0;
      position: relative;
      h3 {
        font: 16px/18px $font-medium;
        color: $tc-bk;
        position: relative;
        padding-left: 30px;
        padding-bottom: 10px;
      }
      h3:before {
        content: "";
        position: absolute;
        left: -10px;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        border: 1px solid $primary-bg;
        background: #fff;
      }
    }
    .box:after {
      content: "";
      position: absolute;
      top: 0;
      left: 8px;
      width: 1px;
      height: 100%;
      background: #000;
      z-index: -1;
      display: none;
    }
    .box:first-child {
      padding-top: 0;
    }
    .box:last-child {
      padding-bottom: 0;
    }
    .box.active h3:before {
      background: $primary-bg;
    }
    .inner-box {
      padding: 15px 25px 25px 25px;
      margin-left: 30px;
      border-radius: 15px;
      border: 1px solid $tc-gray;
      max-width: 400px;
      p {
        font: 16px/20px $font-regular;
        color: $tc-bk;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      ul {
        padding-top: 20px;
        li {
          font: 16px/18px $font-regular;
          color: $tc-bk;
          padding-top: 8px;
          padding-bottom: 8px;
          .lft {
            display: inline-block;
            vertical-align: middle;
            width: 60%;
            text-align: left;
            span {
              float: none;
            }
            span.action {
              text-transform: uppercase;
            }
          }
          .center {
            display: inline-block;
            vertical-align: middle;
            width: 15%;
            text-align: center;
          }
          .rgt {
            display: inline-block;
            vertical-align: middle;
            width: 25%;
            text-align: right;
          }
        }
        li span {
          float: right;
        }
        li .des-box {
          font: 18px/18px $font-regular;
          width: 100%;
          resize: none;
          min-height: 100px;
          border-radius: 0;
          padding: 10px;
        }
      }
    }
    .inner-box.bundle {
      max-width: 50%;
      .accordion-body {
        p {
          font-size: 13px;
        }
      }
    }
  }
  .calldetails-inner:last-child .box-blk {
    padding-bottom: 0;
  }
  .box-blk.options-blk .inner-box ul {
    padding-top: 0;
  }
  .modify-edit-btn {
    position: absolute;
    top: -44px;
    right: -100px;
    a {
      display: block;
      // width: 40px;
      // height: 40px;
      // background: url("../images/modify-edit.png") no-repeat #a1a1a1;
      color: #000;
    }
  }
  .options-notes {
    padding-top: 10px;
  }
  .options-notes p strong {
    display: block;
    padding-bottom: 5px;
  }
}
// Call details > end

// Close call > start
.admin-close-call {
  padding-left: 40px;
  margin-top: 144px;
  .box-blk {
    max-width: 450px;
    .box {
      padding: 40px;
      border: 1px solid #555555;
      border-radius: 15px;
      background: #ebebeb;
      h3 {
        font: 20px/20px $font-medium;
        color: $tc-bk;
      }
      .list-blk {
        padding: 20px 0;
        .form-check {
          width: 33.33333333333333%;
          margin-right: 0;
          .form-check-input {
            border-color: $tc-bk;
            cursor: pointer;
            background-color: transparent;
          }
          .form-check-label {
            font: 16px/20px $font-medium;
            color: $tc-bk;
            cursor: pointer;
          }
          .form-check-input:checked {
            background-color: #555555;
            border-color: #555555;
            background-image: url("../images/check-icon.png");
          }
          .form-check-input:active {
            filter: brightness(100%);
          }
          .form-check-input:focus {
            box-shadow: none;
          }
        }
      }
      .input-fields-blk {
        .form-group {
          display: inline-block;
          vertical-align: top;
          width: 100%;
          padding: 10px 0;
          .form-control {
            border: 1px solid #5d5d5d;
            font: 16px/20px $font-medium;
            padding: 12px;
            border-radius: 0;
          }
          .form-control:focus {
            box-shadow: none;
          }
          .form-control::placeholder {
            color: $tc-bk;
          }
          textarea {
            min-height: 120px;
            resize: none;
          }
        }
        .close-btn {
          padding-top: 10px;
          a {
            font: 16px/20px $font-medium;
            color: $tc-wt;
            padding: 10px 20px;
            background: #666666;
            max-width: 150px;
            display: block;
            text-align: center;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
// Close call > end

//error coponent > start

.admin-error-page{
  .add-error{
    .new-call-btn{
      width: 100%;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

// bundles component > start
.admin-bundleTable-page {
  .add-bundle {
    padding: 0 0 10px;
    text-align: right;
    h2 {
      font: 18px/18px $font-medium;
      color: #477cd8;
      display: inline-block;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .bundle-table-blk {
    // table tbody{border-top: 1px solid #5d5d5d;border-left: 1px solid #5d5d5d;border-right: 1px solid #5d5d5d;border-bottom: 1px solid #5d5d5d;}
    table thead {
      background: $thead-bg;
      border-right: 1px solid #5d5d5d;
    }
    table thead tr th {
      color: $tc-wt;
      padding: 12px;
      font: 16px/20px $font-medium;
      text-align: center;
      border-right: 1px solid #fff;
    background: $thead-bg;

    }
    table thead tr th:last-child {
      border-right: 0;
    }
    table tbody tr td {
      color: $tc-gray;
      padding: 12px;
      font: 16px/20px $font-regular;
      border: 1px solid #5d5d5d;
    }
    table tbody tr td a {
      display: inline-block;
      vertical-align: top;
      text-decoration: underline !important;
      color: #477cd8;
      position: relative;
      padding: 0 5px;
    }
    table tbody tr td a:after {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 1px;
      background: #000;
    }
    table tbody tr td a:first-child::after {
      content: none;
    }
  }
}
.admin-bundlesTabs-page {
  .select-options-blk {
    padding-top: 30px;
  }
  .membership-blk {
    padding-top: 10px;
    text-align: right;
    p {
      font: 18px/18px $font-medium;
      color: $tc-bk;
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
    }
    .member-select {
      display: inline-block;
      vertical-align: middle;
      min-width: 120px;
    }
    .text-box {
      width: 120px;
      border: 1px solid #5d5d5d;
      border-radius: 12px;
      color: $tc-bk;
      background: transparent;
      border: 1px solid #5d5d5d;
      font: 16px/20px $font-medium;
      border-radius: 12px;
      padding: 12px;
    }
  }
  .forms-blk {
    .form-group {
      padding: 10px 40px 10px 0;
      position: relative;
      .form-control {
        color: $tc-bk;
        background: transparent;
        border: 1px solid #5d5d5d;
        font: 16px/20px $font-medium;
        border-radius: 12px;
        padding: 12px;
      }
      .form-control::placeholder {
        color: $tc-bk;
      }
      .form-control:focus {
        box-shadow: none;
      }
      .b-editbtn {
        position: absolute;
        top: 20px;
        right: 0;
        background: url("../images/icon_edit.png") no-repeat;
        width: 22px;
        height: 22px;
        cursor: pointer;
        margin: 0;
      }
    }
  }
  .bundle-tabs-blk {
    padding-top: 20px;
    padding-bottom: 100px;
    .nav-tabs {
      border-bottom: 1px solid #555555;
      li {
        .nav-link {
          font: 18px/18px $font-medium;
          color: $tc-bk;
          border: 1px solid #555555;
          background: transparent;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 10px 15px;
          min-width: 120px;
        }
        .nav-link.active {
          background: #555555;
          color: $tc-wt;
          border: 1px solid #555555;
        }
      }
    }
    .build-bundle {
      h2 {
        font: 18px/18px $font-medium;
        color: $tc-bk;
        padding: 20px 0 40px;
        span {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .notify-action{
      display: flex;
      justify-content: space-between;

      .list-blk{
        .participt-blk{
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
    .options-tab {
      .req-capital {
        .form-group {
          display: block;
          padding-top: 20px;
          p {
            font: 18px/18px $font-medium;
            color: $tc-bk;
            padding-right: 10px;
            display: inline-block;
            vertical-align: middle;
          }
          .form-control {
            color: $tc-bk;
            background: transparent;
            border: 1px solid #5d5d5d;
            font: 16px/20px $font-medium;
            border-radius: 12px;
            padding: 12px;
            display: inline-block;
            vertical-align: middle;
            width: 25%;
          }
          .form-control:focus {
            box-shadow: none;
          }
        }
      }
      .form-group {
        margin-left: -5px;
        display: inline-block;
        vertical-align: top;
        padding: 0 5px 10px 5px;
        .form-control {
          color: $tc-bk;
          background: transparent;
          border: 1px solid #5d5d5d;
          font: 16px/20px $font-medium;
          border-radius: 12px;
          padding: 12px;
        }
        .form-control::placeholder {
          color: $tc-bk;
        }
        .form-control:focus {
          box-shadow: none;
        }
      }
      .form-lft {
        width: 35%;
        display: inline-block;
        vertical-align: top;
      }
      .options-blk {
        margin-top: 35px;
      }
      .options-blk:first-child {
        margin-top: 0;
      }
      .leg-blk {
        width: 63%;
        display: inline-block;
        vertical-align: top;
        margin-top: -36px;
        position: relative;
        .form-blk {
          padding-top: 10px;
        }
        .form-group.w-30 {
          width: 30%;
        }
        .form-group.w-23 {
          width: 23%;
        }
        h2 {
          font: 16px/16px $font-medium;
          color: $tc-bk;
          padding-bottom: 10px;
        }
        .plus-btn {
          position: absolute;
          bottom: 30px;
          right: -20px;
          background: url("../images/plus-btn.png") no-repeat;
          width: 21px;
          height: 21px;
          cursor: pointer;
        }
        .close-btn {
          background: url("../images/close-icon.png") no-repeat;
          width: 21px;
          height: 21px;
          cursor: pointer;
          margin-top: 10px;
        }
      }
    }
    .equity-tab {
      .req-capital {
        .form-group {
          padding-top: 20px;
          p {
            font: 18px/18px $font-medium;
            color: $tc-bk;
            padding-right: 10px;
            display: inline-block;
            vertical-align: middle;
          }
          .form-control {
            color: $tc-bk;
            background: transparent;
            border: 1px solid #5d5d5d;
            font: 16px/20px $font-medium;
            border-radius: 12px;
            padding: 12px;
            display: inline-block;
            vertical-align: middle;
            width: 25%;
          }
          .form-control:focus {
            box-shadow: none;
          }
        }
      }
      .equity-form-blk {
        position: relative;
        .form-group {
          margin-left: -5px;
          display: inline-block;
          vertical-align: top;
          padding: 0 5px 10px 5px;
          width: 20%;
          .form-control {
            color: $tc-bk;
            background: transparent;
            border: 1px solid #5d5d5d;
            font: 16px/20px $font-medium;
            border-radius: 12px;
            padding: 12px;
          }
          .form-control::placeholder {
            color: $tc-bk;
          }
          .form-control:focus {
            box-shadow: none;
          }
        }
        .form-group.w-40 {
          width: 40%;
        }
        .target-delete-btn {
          position: absolute;
          right: 0px;
          top: 60%;
          background: url("../images/close-icon.png") no-repeat;
          width: 21px;
          height: 21px;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
    }
  }
  .css-yk16xz-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px $font-medium;
    border-radius: 12px;
    padding: 4px 2px;
  }
  .css-1pahdxg-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px $font-medium;
    border-radius: 12px;
    padding: 4px 2px;
    box-shadow: none;
  }
  .css-yk16xz-control:hover {
    border-color: #5d5d5d;
  }
  .css-1pahdxg-control:hover {
    border-color: #5d5d5d;
  }
  .css-1wa3eu0-placeholder,
  .css-tlfecz-indicatorContainer {
    color: $tc-bk;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-26l3qy-menu {
    margin: 0;
    border-radius: 0;
    border: 1px solid #5d5d5d;
    border-top: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    margin-top: -10px;
    z-index: 3;
    text-align: left;
  }
  .css-4ljt47-MenuList {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .css-9gakcf-option {
    font: 16px/20px $font-medium;
  }
  .css-yt9ioa-option,
  .css-1n7v3ny-option {
    font: 16px/20px $font-medium;
    text-align: left;
  }
  .css-4ljt47-MenuList div {
    padding: 8px 12px;
  }
  .css-4ljt47-MenuList div:first-child {
    border-top: 1px solid #5d5d5d;
  }
  .css-4ljt47-MenuList div:hover {
    background: #5d5d5d;
    color: #fff;
  }
  .css-1n7v3ny-option {
    background: #5d5d5d;
    color: #fff;
  }
  .css-yt9ioa-option:hover {
    background: #5d5d5d;
  }
  .css-9gakcf-option {
    background: #5d5d5d;
  }
  // .css-yk16xz-control{border-bottom-left-radius:0;border-bottom-right-radius:0;}
  .css-4ljt47-MenuList div:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}
// bundles component > end

// Showcase component starts
.showcaseblk {
  h3 {
    font: 25px/30px $font-semibold;
    color: $tc-gray;
    position: relative;
    justify-content: space-between;
    display: flex;
    align-items: center;
    button {
      border-style: dashed;
      outline: 0;
      padding: 5px 10px;
      background-color: transparent;
    }
  }
  .hmpgbnr {
    border-bottom: 1px solid #c9c9c9;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
    figure {
      width: 320px;
      height: 115px;
      display: inline-block;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10.5px;
        border: 1px solid #a1a1a1;
      }
    }
    p {
      font: 14px/18px $font-regular;
    }
    .displayTill {
      margin-top: 2.5px;
      margin-bottom: 15px;
      justify-content: space-between;
      display: flex;
      align-items: center;
      .fa-trash-o {
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        color: #dc3545;
      }
    }
    .targetPlan {
      display: flex;
      margin-top: 5px;
      justify-content: space-between;
    }
    .targetPlanParagraph {
      text-transform: capitalize;
    }
  }
  .resultblk {
    border-bottom: 1px solid #c9c9c9;
    .css-2b097c-container {
      display: inline-block;
      width: 200px;
      .css-1okebmr-indicatorSeparator {
        display: none;
      }
      .css-tlfecz-indicatorContainer {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #949494;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .css-1gtu0rj-indicatorContainer {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #949494;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .css-yk16xz-control {
        border: 2px solid #5a5a5a;
        border-radius: 0;
        .css-1wa3eu0-placeholder {
          font: 14px/18px $font-semibold;
          color: $tc-gray;
        }
      }
      .css-1pahdxg-control {
        box-shadow: none !important;
        border: 2px solid #5a5a5a;
        border-radius: 0;
        .css-1wa3eu0-placeholder {
          font: 14px/18px $font-semibold;
          color: $tc-gray;
        }
        &:hover {
          border: 2px solid #5a5a5a;
          border-radius: 0;
        }
      }
      .css-tj5bde-Svg {
        display: none;
      }
      .css-26l3qy-menu {
        top: 85%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .css-4ljt47-MenuList {
          padding: 0;
          overflow: hidden;
          .css-1n7v3ny-option {
            background: #fff;
            &:hover {
              background: #444444;
              color: #fff;
            }
          }
        }
      }
    }
    .memcontblk {
      font-size: 0;
      .lftblk {
        display: inline-block;
        width: 45%;
        vertical-align: middle;
        .blk {
          display: inline-block;
          width: 50%;
          vertical-align: middle;
          text-align: center;
          .innercont {
            width: 200px;
            height: 150px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 1px solid #595959;
            border-radius: 25px;
            background: #eeeeee;
            h4 {
              font: 20px/28px $font-regular;
              color: #000;
              span {
                font: 26px/38px $font-semibold;
                display: block;
                word-break: break-all;
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
          .formblk {
            position: relative;
            display: inline-block;
            label {
              font: 13px/16px $font-regular;
              color: #000;
              padding-left: 20px;
            }
            input {
              appearance: auto;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .rytblk {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
        margin-left: 5%;
        .innercont {
          border: 1px solid #595959;
          aside {
            border-top: 1px solid #595959;
            &:first-child {
              border-top: 0;
            }
            p {
              font-size: 0;
              span {
                font: 18px/24px $font-regular;
                color: #000;
                width: 33.33%;
                display: inline-block;
                position: relative;
                vertical-align: middle;
              }
              i {
                width: 33.33%;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                label {
                  font: 13px/16px $font-regular;
                  color: #000;
                  padding-left: 20px;
                }
                input {
                  appearance: auto;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
  .viewresultblk {
    .selectresultblk {
      .css-2b097c-container {
        &:first-child {
          margin-left: 0;
        }
        display: inline-block;
        width: 200px;
        margin-left: 25px;
        .css-1okebmr-indicatorSeparator {
          display: none;
        }
        .css-tlfecz-indicatorContainer {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #949494;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .css-yk16xz-control {
          border: 2px solid #5a5a5a;
          border-radius: 0;
          .css-1wa3eu0-placeholder {
            font: 14px/18px $font-semibold;
            color: $tc-gray;
          }
        }
        .css-1pahdxg-control {
          box-shadow: none !important;
          border: 2px solid #5a5a5a;
          border-radius: 0;
          .css-1wa3eu0-placeholder {
            font: 14px/18px $font-semibold;
            color: $tc-gray;
          }
          &:hover {
            border: 2px solid #5a5a5a;
            border-radius: 0;
          }
        }
        .css-tj5bde-Svg {
          display: none;
        }
        .css-26l3qy-menu {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          z-index: 3;
          .css-4ljt47-MenuList {
            padding: 0;
            overflow: hidden;
            .css-1n7v3ny-option {
              background: #fff;
              &:hover {
                background: #444444;
                color: #fff;
              }
            }
          }
        }
      }
      button {
        background: #444444;
        color: #fff;
        border-color: #444444;
        margin-left: 25px;
        outline: none !important;
        box-shadow: none !important;
        padding: 7px;
        border-style: none;
      }
    }
  }
  .tableblk {
    table .thead tr td.thead-th {
      font-weight: bold;
    }
    .innerblk {
      position: relative;
      margin-bottom: 20px;
      h4 {
        font: 24px/28px $font-semibold;
        color: $tc-gray;
        margin-bottom: 20px;
      }
      .infoblk {
        h5 {
          font: 14px/18px $font-medium;
          color: $tc-gray;
          display: inline-block;
          background: #eeeeee;
          border: 1px solid #585858;
          padding: 20px 25px;
          border-radius: 10px;
          margin-right: 20px;
          text-transform: uppercase;
          text-align: center;
          span {
            font: 26px/30px $font-semibold;
            color: #000;
            display: block;
          }
        }
      }
    }
    .btnexport {
      button {
        // position: absolute;
        position: static;
        right: 0;
        top: 50%;
        background: #0d6efd;
        border-color: #0d6efd;
        padding: 10px 15px;
        color: #fff;
        font: 14px/18px $font-semibold;
      }
      span {
        display: flex;
        justify-content: end;
      }
    }
    .controlRow__root {
      margin: 0px 0 20px;
      position: relative;
      .input-group {
        margin-left: -10px;
        input {
          border-color: #444444;
          border-radius: 0;
          outline: 0;
          &:focus {
            box-shadow: none !important;
          }
        }
      }
      .input-group-append {
        .btn.btn-primary {
          padding: 18px 20px;
          position: relative;
          &:before {
            content: "";
            width: 2px;
            height: 15px;
            background: #fff;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
          &:after {
            content: "";
            width: 15px;
            height: 2px;
            background: #fff;
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
      .ButtonGroup__root {
        position: absolute;
        right: 0;
      }
    }
    .table-datatable__root {
      margin: 0 auto 60px;
      padding: 0 20px;
      border: 1px solid #444444;
      td {
        border-left: 1px solid #444444;
        padding: 20px;
        &:first-child {
          border-left: 0;
        }
      }
    }
  }
}
.modalblk {
  .modal-content {
    border-radius: 30px;
    border: 2px solid #585858;
    padding: 25px 30px;
    .modal-body {
      padding: 0;
      h3 {
        font: 25px/30px $font-semibold;
        color: #000;
      }
      .mdl-innerblk {
        .error-msg {
          font: 15px/19px $font-regular;
          color: red;
        }
        .dndblk {
          width: 90%;
          height: 200px;
          text-align: center;
          position: relative;
          margin: 0 auto;
          border: 1px dashed #bdbdbd;
          border-radius: 0;
          margin-bottom: 10px;
          label {
            font: 25px/30px $font-regular;
            color: #000;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 10%;
            z-index: 1;
          }
          #imagefile {
            width: 100%;
            height: 100%;
            background: none;
            font-size: 0;
            position: absolute;
            z-index: 4;
            outline: 0;
            border: 0;
            left: 0;
            cursor: pointer;
          }
          .btnbrwose {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 20px;
            border: 1px solid #444444;
            outline: 0;
            box-shadow: none;
            z-index: 3;
          }
          .img-responsive {
            z-index: 2;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            height: 100%;
          }
          .overlay {
            background: #a1a1a1;
            width: 100%;
            height: 100%;
            text-align: center;
            z-index: 3;
            position: relative;
            display: flex;
            span {
              display: flex;
              align-items: center;
              margin: 0 auto;
              color: #fff;
            }
          }
        }
        i {
          color: #b4b4b4;
          font-size: 12px;
        }
        .modalformblk {
          width: 90%;
          margin: 25px auto 35px;
          h4 {
            font: 16px/20px $font-regular;
            color: #000;
            text-align: left;
            margin-bottom: 10px;
          }
          .react-datepicker-wrapper {
            width: 100%;
            .react-datepicker__input-container {
              input {
                border-radius: 0;
                font: 12px/14px $font-semibold;
                color: #000;
                padding: 8px 5px;
                margin-bottom: 5px;
                max-width: 100%;
              }
              input::placeholder {
                color: #000;
              }
            }
          }
          .react-datepicker-popper {
            z-index: 10;
          }
          aside {
            position: relative;
            text-align: left;
            label {
              font: 13px/16px $font-regular;
              color: #000;
              padding-left: 20px;
              i {
                border: 1px solid #000;
                width: 10px;
                height: 10px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                overflow: hidden;
                &::before {
                  content: "";
                  position: absolute;
                  background: #000;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  transform: scale(0);
                }
              }
            }
            .firstblk {
              border-right: 1px solid $tc-gray;
            }
            input[type="checkbox"] {
              appearance: auto;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            input[type="radio"] {
              display: none;
            }
            input[type="radio"]:checked + label {
              i::before {
                transform: scale(1);
              }
            }
          }
        }
        .btnconfirm {
          background: #5d5d5d;
          border: 1px solid #5d5d5d;
          border-radius: 15px;
          font: 20px/24px $font-medium;
          color: $tc-wt;
          padding: 10px 55px;
        }
        .btnconfirm.disabled {
          opacity: 0.5;
          color: #959595;
          pointer-events: none;
        }
      }
    }
  }
}
// Showcase component ends

// Video Block starts
.vidblk {
  .addbtn {
    button {
      border: 1px solid #555;
      background: #555;
      font: 16px/16px $font-semibold;
      color: #fff;
    }
  }
  .vid-mainblk {
    padding-bottom: 50px;
    .vid-navblk {
      font-size: 0;
      li {
        color: #000;
        font: 16px/20px $font-regular;
        border: 0;
        background: transparent;
        cursor: pointer;
        &.active span {
          background: #555;
          color: #fff;
          font-family: $font-medium;
        }
        span {
          border: 1px solid #030303;
          border-right: 0;
        }
      }
      li:last-child {
        position: relative;
        padding-left: 40px;
        border-right: 1px solid #030303;
      }
      li:nth-child(4) span {
        border-right: 1px solid #030303;
      }
      li:last-child::before {
        content: "";
        position: absolute;
        left: 20px;
        top: 0;
        width: 2px;
        height: 100%;
        background: #555;
      }
    }
    .vid-contblk {
      border: 1px solid #585858;
      .membernav {
        font-size: 0;
        li {
          color: #000;
          font: 16px/20px $font-regular;
          border: 1px solid #030303;
          background: transparent;
          cursor: pointer;
          &.active {
            background: #555;
            color: #fff;
            font-family: $font-medium;
          }
        }
      }
      .memcontblk {
        .vidfile {
          border: 1px solid #000;
          background: #000;
          line-height: 0;
          width: 240px;
          height: 152px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
        .vidfileinfo {
          padding-top: 25px;
          h2 {
            font: 15px/18px $font-medium;
            color: #000;
            span {
              font: 14px/18px $font-regular;
              color: $tc-gray;
            }
          }
          p {
            font: 14px/18px $font-regular;
            color: $tc-gray;
            padding-top: 10px;
            strong {
              font: 14px/18px $font-medium;
              color: #000;
            }
          }
          .featured-btn {
            width: 20px;
            height: 19px;
            display: block;
            background: url("../images/start-blk.png") no-repeat 0 0;
            text-indent: -999em;
            cursor: pointer;
            transition: all 0.1s;
            position: absolute;
            top: 0;
            right: 65px;
          }
          .featured-btn.active-btn {
            background: url("../images/start-active.png") no-repeat 0 0;
          }
        }
      }
    }
  }
}
.modalblkk {
  .modal-content {
    border-radius: 30px;
    border: 2px solid #585858;
    padding: 25px 30px;
    width: 100% !important;
    .modal-body {
      padding: 0;
      .firstblk {
        aside {
          position: relative;
          text-align: left;
          label {
            font: 20px/26px $font-bold;
            color: #000;
            padding-left: 25px;
            cursor: pointer;
            i {
              border: 1px solid #000;
              width: 16px;
              height: 16px;
              display: inline-block;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 50%;
              overflow: hidden;
              margin-top: -2px;
              &::before {
                content: "";
                position: absolute;
                background: #000;
                top: 2px;
                left: 2px;
                width: 10px;
                height: 10px;
                border-radius: 10px;
                transform: scale(0);
              }
            }
          }
          .firstblk {
            border-right: 1px solid $tc-gray;
          }
          input[type="checkbox"] {
            appearance: auto;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
          input[type="radio"] {
            display: none;
          }
          input[type="radio"]:checked + label {
            i::before {
              transform: scale(1);
            }
          }
        }
      }
      .addblk {
        .btnsave-video.disabled {
          opacity: 0.5;
          color: #959595;
          pointer-events: none;
        }
        .dndblk {
          z-index: 10;
          width: 90%;
          height: 200px;
          margin: 0 auto;
          border: 1px dashed #bdbdbd;
          label {
            font: 25px/30px $font-regular;
            color: #000;
            left: 50%;
            transform: translateX(-50%);
            top: 10%;
            z-index: 1;
          }
          #vidfile {
            width: 100%;
            height: 100%;
            background: none;
            font-size: 0;
            position: absolute;
            z-index: 4;
            outline: 0;
            border: 0;
            left: 0;
            cursor: pointer;
            line-height: 0;
          }
          .btnbrwose {
            position: absolute;
            bottom: 10%;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px 40px;
            border: 1px solid #444444;
            outline: 0;
            box-shadow: none;
            z-index: 3;
            font: 20px/20px $font-medium;
            color: #000;
          }
          .vidresponive {
            z-index: 2;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            height: 100%;
          }
        }
        .formblk {
          .blk {
            font-size: 0;
            label {
              text-align: left;
              display: inline-block;
              vertical-align: middle;
              font: 16px/20px $font-regular;
              color: #000;
              width: 30%;
            }
            .inpfield,
            .dtblk {
              border: 1px solid #5b5b5b;
              border-radius: 0;
              display: inline-block;
              vertical-align: middle;
              width: 70%;
              font: 16px/20px $font-regular;
              color: #000;
              padding: 9px;
            }
            .css-2b097c-container {
              border: 1px solid #5b5b5b;
              border-radius: 0;
              display: inline-block;
              vertical-align: middle;
              width: 70%;
              .css-g1d714-ValueContainer {
                padding: 13px 8px;
              }
              .css-1pahdxg-control {
                border-color: transparent !important;
                box-shadow: none !important;
                &:hover {
                  border-color: transparent !important;
                  box-shadow: none !important;
                }
              }
              .css-26l3qy-menu {
                margin: 0;
                border-radius: 0;
                border: 1px solid #555;
              }
              .css-1uccc91-singleValue,
              .css-1wa3eu0-placeholder {
                font: 16px/20px $font-semibold;
                color: #000;
              }
              .css-1okebmr-indicatorSeparator {
                background-color: transparent !important;
              }
              .addsele__option {
                font: 14px/18px $font-regular;
                color: $tc-gray;
              }
              .css-1n7v3ny-option {
                background: #555555;
                color: #fff;
              }
              .css-9gakcf-option {
                background: #555555;
                color: #fff;
              }
            }
          }
          .dndblk {
            z-index: 10;
            width: 90%;
            height: 150px;
            margin: 0 auto;
            border: 1px dashed #bdbdbd;
            label {
              font: 16px/20px $font-regular;
              color: #000;
              left: 50%;
              transform: translateX(-50%);
              top: 10%;
              z-index: 1;
              width: 60%;
            }
            #imgefile {
              width: 100%;
              height: 100%;
              background: none;
              font-size: 0;
              position: absolute;
              z-index: 4;
              outline: 0;
              border: 0;
              left: 0;
              cursor: pointer;
              line-height: 0;
            }
            .btnbrwose {
              position: absolute;
              bottom: 10%;
              left: 50%;
              transform: translateX(-50%);
              padding: 5px 40px;
              border: 1px solid #444444;
              outline: 0;
              box-shadow: none;
              z-index: 3;
              font: 20px/20px $font-medium;
              color: #000;
            }
          }
          .react-datepicker-popper {
            z-index: 11 !important;
          }
          .react-datepicker-time__input {
            border-radius: 0;
            padding: 5px 10px;
          }
          h4 {
            font: 16px/20px $font-regular;
            color: #000;
          }
          .dtblk {
            padding: 9px;
            font: 14px/20px $font-semibold;
            color: #000;
            display: inline-block;
            width: 100%;
            border: 1px solid #5b5b5b;
          }
        }
        .btnsave {
          padding: 10px 40px 10px;
          border-radius: 12px;
          border: 1px solid #555;
          outline: 0;
          box-shadow: none;
          z-index: 3;
          font: 20px/20px $font-medium;
          color: #fff;
          width: 100%;
          text-transform: uppercase;
          background: #555;
        }
      }
    }
  }
}
// Video Block starts
// User Management starts
.usermanage {
  width: 100%;
  .react-datepicker-wrapper {
    max-width: 150px;
    display: inline-block;
    input {
      border-radius: 0;
      padding: 5px;
      display: inline-block;
      width: 100%;
    }
  }
  .controlRow__root {
    margin-bottom: 20px;
    .input-group-append {
      .btn.btn-primary {
        padding: 18px 20px;
        position: relative;
        z-index: -1;
        &:before {
          content: "";
          width: 2px;
          height: 15px;
          background: #fff;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          content: "";
          width: 15px;
          height: 2px;
          background: #fff;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .ButtonGroup__root {
      float: right;
    }
  }

  .btnsearch {
    width: 100%;
    border: 0;
    background: #444444;
    outline: 0;
    color: #fff;
    height: 100%;
    margin-left: 15px;
  }
  .btnexport {
    position: relative;
    // span {
      button {
        background: #444444;
        border-color: #444444;
        padding: 10px 15px;
        color: #fff;
        width: 100%;
        font: 14px/18px $font-semibold;
        box-shadow: none;
        outline: none !important;
        border-style: none !important;
      }
    // }
  }
  table thead {
    background: $thead-bg;
    border-right: 1px solid #5d5d5d;
  }
  table thead tr td {
    color: $tc-wt;
    padding: 12px;
    font: 16px/20px $font-medium;
    text-align: center;
    border-right: 1px solid #fff;
    vertical-align: middle;
    background: $thead-bg;
  }
  table thead tr th:last-child {
    border-right: 0;
  }
  table tbody tr td {
    color: $tc-gray;
    padding: 12px;
    font: 16px/20px $font-regular;
    border: 1px solid #5d5d5d;
  }
  table tbody tr td a {
    display: inline-block;
    vertical-align: top;
    text-decoration: underline !important;
    color: #477cd8;
    position: relative;
    padding: 0 5px;
  }
  table tbody tr td a:after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 1px;
    background: #000;
  }
  table tbody tr td a:first-child::after {
    content: none;
  }
}
//User Management ends

// Event Starts
.eventblk {
  .dndblk {
    z-index: 10;
    width: 90%;
    height: 200px;
    margin: 0 auto;
    border: 1px dashed #bdbdbd;
    text-align: center;
    position: relative;
    label {
      font: 18px/30px $font-regular;
      color: #000;
      top: 10%;
      display: inline-block;
      z-index: 1;
      width: 70%;
    }
    #vidfilee {
      width: 100%;
      height: 100%;
      background: none;
      font-size: 0;
      position: absolute;
      z-index: 4;
      outline: 0;
      border: 0;
      left: 0;
      cursor: pointer;
      line-height: 0;
    }
    .btnbrwose {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px 40px;
      border: 1px solid #444444;
      outline: 0;
      box-shadow: none;
      z-index: 3;
      font: 20px/20px $font-medium;
      color: #000;
    }
    .vidresponive {
      z-index: 2;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
      height: 100%;
    }
  }
  .controlRow__root {
    margin-bottom: 20px;
    .input-group-append {
      .btn.btn-primary {
        padding: 18px 20px;
        position: relative;
        &:before {
          content: "";
          width: 2px;
          height: 15px;
          background: #fff;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:after {
          content: "";
          width: 15px;
          height: 2px;
          background: #fff;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }
    }
    .ButtonGroup__root {
      float: right;
    }
  }
  table thead {
    background: $thead-bg;
    border-right: 1px solid #5d5d5d;
  }
  table thead tr td {
    color: $tc-wt;
    padding: 12px;
    font: 16px/20px $font-medium;
    text-align: center;
    border-right: 1px solid #fff;
    vertical-align: middle;
    background: $thead-bg;

  }
  table thead tr th:last-child {
    border-right: 0;
  }
  table tbody tr td {
    color: $tc-gray;
    padding: 12px;
    font: 16px/20px $font-regular;
    border: 1px solid #5d5d5d;
  }
  table tbody tr td a {
    display: inline-block;
    vertical-align: top;
    text-decoration: underline !important;
    color: #477cd8;
    position: relative;
    padding: 0 5px;
  }
  table tbody tr td a:first-child::after {
    content: none;
  }
}
//Event Ends

//Sales > start
.admin-sales {
  padding-bottom: 50px;
  .btnexport span {
    position: relative;
    padding-right: 25px;
  }
  .btnexport span::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    background: url("../images/download.png") no-repeat;
    width: 20px;
    height: 28px;
    cursor: pointer;
    margin: 0;
    transform: translate(0, -50%);
  }
  .btnexport span button {
    background: transparent;
    color: #000;
    padding: 0;
    font: 16px/18px "Graphik-Semibold";
  }
  .contblk {
    padding-top: 30px;
  }
  .contblk .input-group input {
    border: 1px solid #5d5d5d;
  }
  .contblk .input-group input:focus {
    box-shadow: none;
  }
  .contblk .input-group .btn-primary {
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
  .contblk .ButtonGroup__root .btn-primary {
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
  .contblk .ButtonGroup__root .btn-primary:disabled,
  .contblk .ButtonGroup__root .btn-primary.disabled {
    background-color: #5d5d5d;
    border-color: #5d5d5d;
  }
  table thead {
    border: 1px solid #5d5d5d;
  }
  table thead tr td:last-child {
    border-right: 0;
  }
  .react-datepicker-wrapper input {
    border-width: 1px;
  }
  .membership-blk {
    padding-top: 10px;
    p {
      font: 18px/18px $font-medium;
      color: $tc-bk;
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
    }
    .member-select {
      display: inline-block;
      vertical-align: middle;
      min-width: 300px;
    }
  }
  .css-yk16xz-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px $font-medium;
    border-radius: 12px;
    padding: 4px 2px;
  }
  .css-1pahdxg-control {
    border: 1px solid #5d5d5d;
    font: 16px/20px $font-medium;
    border-radius: 12px;
    padding: 4px 2px;
    box-shadow: none;
  }
  .css-yk16xz-control:hover {
    border-color: #5d5d5d;
  }
  .css-1pahdxg-control:hover {
    border-color: #5d5d5d;
  }
  .css-1wa3eu0-placeholder,
  .css-tlfecz-indicatorContainer {
    color: $tc-bk;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-26l3qy-menu {
    margin: 0;
    border-radius: 0;
    border: 1px solid #5d5d5d;
    border-top: 0;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-shadow: none;
    margin-top: -10px;
    z-index: 3;
    text-align: left;
  }
  .css-4ljt47-MenuList {
    padding-top: 10px;
    padding-bottom: 0;
  }
  .css-9gakcf-option {
    font: 16px/20px $font-medium;
  }
  .css-yt9ioa-option,
  .css-1n7v3ny-option {
    font: 16px/20px $font-medium;
    text-align: left;
  }
  .css-4ljt47-MenuList div {
    padding: 8px 12px;
  }
  .css-4ljt47-MenuList div:first-child {
    border-top: 1px solid #5d5d5d;
  }
  .css-4ljt47-MenuList div:hover {
    background: #5d5d5d;
    color: #fff;
  }
  .css-1n7v3ny-option {
    background: #5d5d5d;
    color: #fff;
  }
  .css-yt9ioa-option:hover {
    background: #5d5d5d;
  }
  .css-9gakcf-option {
    background: #5d5d5d;
  }
  .css-4ljt47-MenuList div:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .sales-tabs-blk {
    .nav-tabs {
      border-bottom: 1px solid #555555;
      li {
        .nav-link {
          font: 18px/18px $font-medium;
          color: $tc-bk;
          border: 1px solid #555555;
          background: transparent;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          padding: 10px 15px;
          min-width: 120px;
        }
        .nav-link.active {
          background: #555555;
          color: $tc-wt;
          border: 1px solid #555555;
        }
      }
    }
  }
  #call-report {
    table {
      .tbody {
        .tbody-tr {
          .tbody-td:last-child {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  .data-table {
    .table {
      thead {
        th {
          color: $tc-wt;
          padding: 12px;
          font: 16px/20px $font-medium;
          text-align: center;
          border-right: 1px solid #fff;
          vertical-align: middle;
        }
      }
      thead th:last-child {
        border-right: 1px solid #5d5d5d;
      }
      tbody tr td a {
        text-decoration: underline;
        color: $primary-bg;
      }
      tbody tr td.w-18 {
        width: 18%;
      }
    }
  }
  .comments-popup-blk {
    .modal-header {
      border-bottom: 0;
      padding-bottom: 0;
      padding: 20px 20px 10px 20px;
      h5 {
        font: 20px/20px $font-medium;
        color: $tc-bk;
      }
      .btn-close:focus {
        box-shadow: none;
      }
    }
    .modal-body {
      padding-top: 0;
      textarea.form-control {
        border: 1px solid #5d5d5d;
        font: 16px/22px $font-medium;
        padding: 12px;
        border-radius: 0;
        resize: none;
        min-height: 120px;
      }
      textarea.form-control:focus {
        box-shadow: none;
      }
    }
    .modal-footer {
      padding-left: 20px;
      padding-top: 0;
      border-top: 0;
      display: block;
      text-align: left;
      button {
        display: inline-block;
        font: 16px/16px $font-medium;
        color: $tc-wt;
        background: #2b2b2b;
        padding: 10px;
        text-transform: uppercase;
        min-width: 100px;
        border-color: #2b2b2b;
      }
    }
  }
  .accordion-blk {
    padding-top: 10px;
    .accordion-item:last-child {
      margin-bottom: 20px;
    }
    .accordion-header {
      border: 1px solid #5d5d5d;
      border-bottom: 0;
      border-radius: 0;
      margin-bottom: 0;
      button {
        font: 16px/20px $font-medium;
        color: $tc-bk;
        border: 0;
        padding: 12px;
        span {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          padding-right: 20px;
        }
      }
    }
    .accordion-header:last-child {
      border-top: 1px solid #5d5d5d;
      border-bottom: 1px solid #5d5d5d;
    }
    .accordion-button:focus {
      border-color: #5d5d5d;
      box-shadow: none;
    }
    .accordion-button:not(.collapsed) {
      background-color: #5d5d5d;
      color: #fff;
    }
    .accordion-item:first-of-type .accordion-button {
      border-radius: 0;
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-radius: 0;
      border-top: 0;
    }
    .accordion-item:last-child .accordion-header {
      border-bottom: 1px solid #5d5d5d;
    }
    .accordion-item:last-child .accordion-collapse {
      border-bottom: 1px solid #5d5d5d;
    }
    .accordion-collapse {
      border: 1px solid #5d5d5d;
      border-radius: 0;
      border-top: 0;
      border-bottom: 0;
    }
    .accordion-button:not(.collapsed)::after {
      background-image: url("../images/down-arrow.svg");
      transform: rotateX(180deg);
    }
    .accordion-body {
      padding: 12px;
    }
    .accordion-body p {
      font: 16px/24px $font-regular;
      color: $tc-bk;
    }
  }
}
//Sales > end

.trade-error-msg {
  font: 16px/24px $font-regular !important;
  color: red;
  margin-top: -7px;
  margin-bottom: 9px;
}

.trade-success-msg {
  font: 16px/24px $font-regular !important;
  color: green;
  margin-top: -7px;
  margin-bottom: 9px;
}

.upload-image-recommend {
  font: 11px/15px $font-regular;
  margin-left: 7px !important;
  color: rgb(77, 66, 66) !important;
  position: absolute;
}
.upload-image-recommend1 {
  font: 11px/15px $font-regular;
  margin-left: 7px !important;
  color: rgb(77, 66, 66) !important;
  position: absolute;
}

.error-mess{
  font: 20px/14px "Graphik-Bold";
  color: #555555;
  text-align: center;
}

.view-img{
  cursor: pointer;
    color: #1a27ab;
    font-size: 16px;
    font-weight: 500;
}
/* CircularProgressbar */
#cpWrapper {
  max-width: 50%;
  margin: 0 auto;
}
#cpWrapper .CircularProgressbar .CircularProgressbar-trail {
  stroke: #4a4a4a;
  // stroke-width: 6px;
}
#cpWrapper .CircularProgressbar .CircularProgressbar-path {
  stroke: #f67452;
  stroke-width: 12px;
  transition: none;
}
#cpWrapper .CircularProgressbar .CircularProgressbar-text {
  font-size: 8px;
  font-family: "Graphik-Semibold";
  fill: #4a4a4a;
}
#cpWrapper h3 {
  font: 16px/22px $font-bold;
  color: #555;
  text-align: center;
  margin: 10px 0 0;
}
#cpWrapper h3 span {
  text-transform: uppercase;
  display: block;
}
.leftComponentWidth {
  width: 410px !important;
}
.back-btn {
  margin-left: -40px !important;
  margin-top: -5px !important;
  cursor: pointer;
}
.equityEdit {
  cursor: pointer;
  position: relative;
  left: 83%;
  width: 3.4%;
  height: 3%;
}
.TradeLogicMargin {
  margin-bottom: 10px !important;
}
@media (max-width: 3000px) and (min-width: 1600px) {
  .leftComponentWidth {
    // width: 510px !important;
    width: 570px !important;

  }
  .legsItemWidth {
    width: 100px !important;
  }
  .option-rgt-blk .leg-blk .form-blk .form-group1 {
    width: 18%;
    .legsItemWidth1 {
      width: 110px !important;
    }
  }
  .rightComponentWidth {
    width: 730px !important;
  }
}
@media (max-width: 1599px) and (min-width: 1402px) {
  .legsItemWidth {
    width: 100px !important;
  }
  .option-rgt-blk .leg-blk .form-blk .form-group1 {
    width: 18%;
    .legsItemWidth1 {
      width: 110px !important;
    }
  }
  .rightComponentWidth {
    width: 730px !important;
  }
}
// @media (max-width: 1390px) and (min-width: 1351px) {
//   .legsItemWidth {
//     width: 100px !important;
//   }
//   .option-rgt-blk .leg-blk .form-blk .form-group1{
//     width:24%;
//     .legsItemWidth1 {
//       width: 150px !important;
//     }
//   }
//   .rightComponentWidth {
//     width: 700px !important;
//   }
// }

@media (max-width: 1401px) and (min-width: 1200px) {
  .bundle-scrip-head {
    font: 80% $font-medium !important;
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100px;
  }
  .bundle-scrip-head1 {
    font: 80% $font-medium !important;
    margin-left: 10px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    width: 100px;
    height: 25px !important;
  }
  .leftComponentWidth {
    width: 400px !important;
  }
  .option-rgt-blk .leg-blk .form-blk .form-group {
    width: 20%;
    padding-left: 3px;
  }
  .option-rgt-blk .leg-blk .form-blk .form-group.w-6 {
    width: 7%;
  }
  .equityEdit {
    cursor: pointer;
    position: relative;
    left: 80% !important;
    width: 3.4%;
    height: 3%;
  }
  .option-rgt-blk .leg-blk .form-blk .form-group1 {
    width: 22%;
    .legsItemWidth1 {
      width: 110px !important;
    }
  }
}

.bundleTopMargin {
  margin-top: 15px !important;
}

#hedgeometerPopUp::placeholder {
  color: grey;
}

.head-1 {
  font: 18px/20px "Graphik-Medium";
  margin-left: 7px;
}

.head-label{
  margin-bottom: 5px;
}
#bundleTitle::placeholder {
  color: grey !important;
}

.rupeeSymbol {
  font: 25px "Graphik-Medium";
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}
.text-decoration-scrip {
  color: #0d6efd;
  font: 21px "Graphik-Medium";
  cursor: pointer;
}
.bundle-head {
  font: 25px "Graphik-Medium";
  margin-top: 25px;
  margin-bottom: 20px;
}
.bundles-w-30 {
  width: 20% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.bundles-w-10 {
  width: 10% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.stg-bundles-w-10 {
  width: 17% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.stg1-bundles-w-10 {
  width: 12% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.bundles-notes-textarea {
  color: $tc-bk;
  background: transparent;
  border: 1px solid #5d5d5d;
  font: 16px/20px $font-medium;
  border-radius: 12px;
  padding: 12px;
  width: 70% !important;
  height: 200px !important;
}
.notesTopMargin {
  margin-top: 30px;
}
.bundles-notes-head {
  width: 200px;
  font: 18px/20px $font-medium;
}
.bundle-scrip-head {
  font: 90% $font-medium;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 100px;
}
.bundle-scrip-head1 {
  font: 90% $font-medium;
  margin-left: 10px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  width: 200px;
  height: 25px !important;
}
.bundles-legs-blk {
  margin-top: 30px;
  padding-left: 30px;
  cursor: pointer;
  h2 {
    font: 16px/20px $font-medium;
    color: $tc-bk;
    margin-bottom: 8px;
    margin-top: -25px;
  }
}
.bundles-option-margintop {
  margin-top: 20px;
}

.bundles-legs {
  width: 10% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.bundles-legs-select {
  width: 14% !important;
  input::placeholder {
    font: 13px "Graphik-Medium" !important;
    color: grey !important;
  }
}
.plus-btn {
  position: absolute;
  bottom: 30px;
  right: -20px;
  background: url("../images/plus-btn.png") no-repeat;
  width: 21px;
  height: 21px;
  cursor: pointer;
}
.close-btn {
  background: url("../images/close-icon.png") no-repeat;
  width: 21px;
  height: 21px;
  cursor: pointer;
  margin-top: 35px;
}
.close-btn1 {
  background: url("../images/close-icon.png") no-repeat;
  width: 21px;
  height: 21px;
  cursor: pointer;
  margin-top: 60px;
  margin-left: 8px;
}
.target-delete-btn-not-exit {
  background: url("../images/close-icon.png") no-repeat;
  width: 21px;
  height: 21px;

  cursor: pointer;
}

.admin-bundlesTabs-page .css-1wa3eu0-placeholder,
.admin-bundlesTabs-page .css-tlfecz-indicatorContainer {
  color: grey;
  font: 14px/20px "Graphik-Medium";
}

// bundle details page css
.bundle-details-page {
  padding-top: 30px;
}
.bundle-details-page .subhead {
  padding-left: 0;
}
.bundle-details-page .subhead h3 {
  font: 16px/20px "Graphik-Medium";
  color: #000;
}
.bundle-details-page .subhead h2 {
  font: 26px/26px "Graphik-Medium";
  color: #000;
}
.bundle-details-page .box-blk {
  border-left: 0;
  max-width: 50%;
}
.bundle-details-page .box-blk .box h3 {
  padding-left: 0;
}
.bundle-details-page .box-blk .box h3:before {
  display: none;
}
.bundle-details-page .box-blk .inner-box {
  margin-left: 0;
  padding: 0;
  border: 0;
  max-width: 100%;
}
.bundle-details-page .box-blk .box {
  padding-top: 25px;
  padding-bottom: 10px;
}
.bundle-details-page .bundle-table-blk {
  padding-bottom: 10px;
}
.bundle-details-page .bundle-table-blk table {
  border: 1px solid #5d5d5d;
  margin-bottom: 0;
}
.bundle-details-page .bundle-table-blk table thead {
  background: #5d5d5d;
  color: #fff;
}
.bundle-details-page .bundle-table-blk table thead th {
  font: 16px/20px "Graphik-Medium";
  padding: 10px;
  border-right: 1px solid #fff;
  width: 25%;
}
.bundle-details-page .bundle-table-blk table thead th:last-child {
  border-right: 0;
}
.bundle-details-page .bundle-table-blk table tbody tr {
  border-bottom: 1px solid #5d5d5d;
}
.bundle-details-page .bundle-table-blk table tbody tr:last-child {
  border-bottom: 0;
}
.bundle-details-page .bundle-table-blk table tbody tr td {
  color: #555555;
  font: 16px/20px "Graphik-Regular";
  border-bottom-width: 0;
  border-style: initial;
  padding: 10px;
  border-right: 1px solid #555555;
}
.bundle-details-page .bundle-table-blk table tbody tr td span {
  padding-top: 2px;
}
.bundle-details-page .bundle-table-blk table tbody tr td i {
  display: block;
  font-style: normal;
}
.bundle-details-page .bundle-table-blk table tbody tr td span.top-strip {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background: #a1a1a1;
  padding: 2px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid gray;
}
.bundle-details-page .bundle-table-blk table tbody tr td span.name {
  display: block;
}
.bundle-details-page .notes p {
  font: 16px/20px "Graphik-Regular";
  color: #555555;
  padding-top: 5px;
  padding-bottom: 10px;
}

.bundle-details-page .options-blk .subhead {
  padding-bottom: 10px;
}
.bundle-details-page h3.modify-title {
  font: 16px/20px "Graphik-Medium";
  padding: 15px 15px;
  border-radius: 10px;
  color: #41464b;
  background-color: #e2e3e5;
  border: 1px solid #d3d6d8;
}
.bundle-details-page .options-blk .box,
.bundle-details-page .options-blk .box .inner-box ul {
  padding-top: 0;
}
.bundle-details-page .accordion-blk .accordion-item {
  padding-bottom: 10px;
  border: 0;
  border-bottom: 1px solid #555;
}
.bundle-details-page .accordion-blk .accordion-item:last-child {
  padding-bottom: 0;
}
.bundle-details-page .accordion-blk .accordion-button {
  font: 18px/22px "Graphik-Medium";
  border-radius: 0;
  padding: 15px;
  background: #5d5d5d;
  color: #fff;
}
.bundle-details-page .accordion-blk .accordion-button:focus {
  box-shadow: none;
  border-bottom: 1px solid #5d5d5d;
  border-color: #5d5d5d;
}
.bundle-details-page .accordion-blk .accordion-button:not(.collapsed) {
  background-color: #5d5d5d;
}
.bundle-details-page .accordion-blk .accordion-collapse {
  border: 1px solid #555555;
  border-top: 0;
  border-radius: 0;
}
.bundle-details-page .accordion-blk .accordion-button::after {
  background-image: url("../images/down-arrow.svg");
  background-size: 100% 100%;
  width: 27px;
  height: 21px;
  transition: all 0.5s;
}
.bundle-details-page .accordion-blk .accordion-button:not(.collapsed)::after {
  transform: rotateX(180deg);
}
.bundle-details-page .accordion-blk .accordion-body {
  padding: 15px;
  background: #a1a1a1;
}
.bundle-details-page
  .accordion-blk
  .accordion-item:last-of-type
  .accordion-button.collapsed {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

// .bundle-details-page .accordion-blk .accordion-body .child-accordion .accordion-item{border-bottom: 1px solid #555;}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-item:last-child {
  border-bottom: 0;
  padding-bottom: 10px;
  border-radius: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button {
  padding: 10px;
  border-radius: 0;
  background: #fff;
  border: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-body {
  background: #fff;
  border-top: 1px solid #555;
  padding: 10px;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button::after {
  background-image: url("../images/down-arrow-block.svg");
  background-size: 100% 100%;
  width: 27px;
  height: 21px;
}

.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-lft {
  display: inline-block;
  vertical-align: middle;
  width: 63%;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-lft
  h3 {
  font: 18px/22px "Graphik-Medium";
  color: #000;
  text-align: left;
  padding: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-lft
  p {
  text-align: left;
  padding: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-lft
  p
  span {
  float: none;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt {
  display: inline-block;
  vertical-align: middle;
  width: 30%;
  text-align: right;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt
  p {
  text-align: right;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt
  .price-blk {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  padding: 5px;
  background: #a1a1a1;
  border-radius: 5px;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt
  p
  span {
  display: block;
  float: none;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt
  .price-blk
  span.exit-price {
  font: 12px/12px "Graphik-Regular";
  color: #fff;
  display: block;
  float: none;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .accordion-button
  .options-rgt
  .price-blk
  span.total {
  font: 16px/20px "Graphik-Medium";
  color: #fff;
  display: block;
  float: none;
}
.bundle-details-page .accordion-blk .child-accordion .accordion-collapse {
  border-bottom: 1px solid #555;
  border-radius: 0;
  border: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk {
  border: 1px solid #555;
  border-radius: 5px;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table {
  width: 100%;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead {
  background: #555;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead
  th {
  font: 16px/20px "Graphik-Medium";
  color: #fff;
  padding: 10px;
  border-left: 1px solid #fff;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead
  th:first-child {
  border-left: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead
  th:nth-child(1) {
  width: 60%;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead
  th:nth-child(2) {
  width: 20%;
  text-align: center;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  thead
  th:nth-child(3) {
  width: 20%;
  text-align: center;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  tbody
  tr
  td {
  font: 16px/20px "Graphik-Regular";
  color: #555;
  padding: 10px;
  border-left: 1px solid #555;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  tbody
  tr
  td:first-child {
  border-left: 0;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  tbody
  tr
  td:nth-child(2) {
  text-align: center;
}
.bundle-details-page
  .accordion-blk
  .accordion-body
  .child-accordion
  .child-table-blk
  table
  tbody
  tr
  td:nth-child(3) {
  text-align: center;
}
.bundle-details-page .box-blk .inner-box ul li {
  padding-bottom: 10px;
}

.bundle-details-page .equity-blk .box-blk {
  padding-bottom: 0;
}
.bundle-details-page .options-blk .subhead {
  padding-top: 10px;
}

.bundle-details-page .equity-options-wrapper {
  padding-top: 15px;
}
.bundle-details-page .bundle-tabs-blk {
  padding-top: 20px;
}
.bundle-details-page .bundle-tabs-blk .nav-tabs {
  border-bottom: 1px solid #555555;
}
.bundle-details-page .bundle-tabs-blk .nav-tabs li .nav-link {
  font: 18px/18px $font-medium;
  color: #000;
  border: 1px solid #555555;
  background: transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px 15px;
  min-width: 120px;
}
.bundle-details-page .bundle-tabs-blk .nav-tabs li .nav-link.active {
  background: #555555;
  color: #fff;
  border: 1px solid #555555;
}
.bundle-details-page .box-blk {
  padding-bottom: 15px;
}
.bundle-details-page .tracker-blk table {
  width: 100%;
}
.bundle-details-page .tracker-blk thead tr th {
  width: 20%;
  border-right: 1px solid #fff;
}
.bundle-details-page .tracker-blk thead tr th:last-child {
  border-right: 0;
}
.bundle-details-page .tracker-blk tbody tr {
  border-bottom: 1px solid #555;
}
.bundle-details-page .tracker-blk tbody tr:last-child {
  border-bottom: 0;
}
.bundle-details-page .tracker-blk tbody tr td {
  border-right: 1px solid #555;
}
.bundle-details-page .tracker-blk tbody tr td span {
  display: block;
}
.bundle-details-page .tracker-blk tbody tr td:last-child {
  border-right: 0;
}
.bundle-details-page .table-blk {
  padding: 30px 0;
}
.bundle-details-page .table-blk table thead {
  border: 1px solid #5d5d5d;
}

// video component
.publish-on-off {
  input {
    display: none;
  }
  label {
    cursor: pointer;
    padding-right: 35px;
    position: relative;
    display: block;
    font: 16px/20px "Graphik-Regular";
    color: #000;
    width: 36%;
  }

  input[type="checkbox"] {
    position: absolute;
    visibility: hidden !important;
  }

  input[type="checkbox"] + label {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -11.5px;
      box-sizing: border-box;
    }

    &:before {
      width: 50px;
      height: 25px;
      right: 0px;
      background: #fff;
      border: 1px solid #5b5b5b;
      border-radius: 15px;
    }
    &:after {
      width: 25px;
      height: 25px;
      right: 25px;
      background: #bdbdbd;
      border-radius: 50%;
      transition: all 200ms ease-out;
    }
  }

  input[type="checkbox"]:checked + label {
    &:after {
      right: 0px;
      background: #f67452;
    }
  }
}
.addblk .formblk .react-datepicker-wrapper {
  width: 100%;
}
.addblk
  .formblk
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  margin-top: 5px !important;
}
.addblk .formblk .addsele__control.css-yk16xz-control {
  border-radius: 0;
  border: 0;
}

.livevideo-table-blk {
  padding-bottom: 30px !important;
  overflow-x: scroll;
  overflow-y: hidden;
}
.livevideo-table-blk td span {
  display: block;
}
.livevideo-table-blk td.edit-fields span {
  font-family: $font-medium;
  text-decoration: underline;
  cursor: pointer;
  padding-bottom: 5px;
}
.livevideo-table-blk .table-blk {
  padding-bottom: 20px;
  padding-left: 2px;
}
.livevideo-table-blk .livevideo-table thead tr th {
  border-right: 1px solid #fff;
}
.livevideo-table-blk .livevideo-table thead tr th:last-child {
  border-right: 0;
}
.livevideo-table-blk .livevideo-table tbody tr td {
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}
.livevideo-table-blk .livevideo-table tbody tr td:last-child {
  border-right: 0;
}

.modalblkk
  .modal-content
  .modal-body
  .addblk
  .newvideo-saveBtn
  .btnsave-video.disabled {
  opacity: 0.5;
  color: #959595;
  pointer-events: none;
}
.img-size {
  color: #b4b4b4;
  font-size: 12px;
}

.featured-blk label {
  position: relative;
  padding-right: 66px;
}
.featured-blk label span {
  width: 25px;
  height: 25px;
  border: 1px solid #555;
  border-radius: 5px;
  top: 0;
  position: absolute;
  right: -97px;
  transition: all 0.3s;
  cursor: pointer;
}
.featured-blk label span:before {
  content: "";
  position: absolute;
  left: 3px;
  top: 4px;
  background: url("../images/tick-orange.png") no-repeat;
  width: 18px;
  height: 13px;
  border-radius: 12px;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all 0.3s;
}
.featured-blk input[type="checkbox"]:checked + label span:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.3s;
}
.call-final-btn {
  padding-top: 10px;

  a {
    font: 16px/20px $font-medium;
    color: white !important;
    padding: 10px 20px;
    background: #666666;
    max-width: 150px;
    display: block;
    text-align: center;
    border-radius: 8px;
  }
}
.css-14jk2my-container {
  border: 1px solid #5d5d5d;
  font: 16px/20px "Graphik-Medium";
  border-radius: 12px;
  padding: 4px 2px;
  background-color: white !important;
}
.css-1fhf3k1-control {
  background-color: white !important;
  border: none !important;
}
//Login page
.innerpage {
  padding: 0px 0 0;
  margin-top: -120px;
}
.loginpage label {
  font-size: 16px;
  font-family: "Verdana";
  color: #4a4a4a;
}

.loginpage .form-control {
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  background-color: transparent;
  border: 1px solid #ccc;
  border-width: 0 0 1px;
  border-radius: 0;
  box-shadow: none;
}

.loginpage .form-group {
  margin: 0 0 15px;
}

.loginpage .form-check {
  margin: 30px 0 40px;
}

.loginpage .form-check-input:checked {
  background-color: #fff;
  border-color: #0e0b20 !important;
}

.loginpage .form-check-input[type="checkbox"] {
  border-radius: 2px;
}

.loginpage .btn-primary,
.loginpage .btn-primary:hover {
  color: #fff;
  background-color: #f15b25;
  border-color: #f15b25;
}

.loginpage .btn {
  font-weight: 700;
  padding: 10px 30px;
  font-size: 16px;
  border-radius: 0 0 0 10px;
  box-shadow: none;
  margin: 0 0 20px;
}

.main-login-block {
  margin: 0 auto;
  padding: 50px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-top: 20%;
}

.main-login-form {
  position: relative;
  z-index: 2;
}

.main-login-header {
  font-family: "Verdana";
  text-align: left;
  margin: 0 0 20px;
  background-image: url("../images/artwork-2.png") !important;
  background-repeat: no-repeat;
}

.main-login-header-first {
  font-size: 30px;
  color: #4a4a4a;
}

.footerlogo {
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 12px;
  color: #0e0b20;
  font-family: "Verdana";
}

.footerlogo img {
  width: 80px;
  height: 30px;
  margin-top: -18px;
  margin-left: 10px;
}

.customLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.25s;
  opacity: 0;
  &.show {
    opacity: 1;
  }
  .loaderIcon {
    width: 38px;
    height: 38px;
    position: fixed;
    left: calc(50% - (38px / 2));
    top: calc(50% - (38px / 2));
    stroke: white;

    .loaderCircleColor {
      stroke: #f15b25;
      stroke-opacity: 1;
    }
  }
}
.logout-btn {
  cursor: pointer;
  color: white;
  font-family: "Verdana";
  font-weight: 600;
  font-size: 17px;
  text-align: right;
  padding-right: 30px;
}
.css-1fhf3k1-control {
  background-color: white !important;
  border: none !important;
}

.livefrom-tab-blk .nav-item button {
  color: #000;
  font: 16px/20px "Graphik-Regular";
  border: 1px solid #030303;
  background: transparent;
  cursor: pointer;
}
.livefrom-tab-blk .nav-item button.active {
  background: #555;
  color: #fff;
  border: 1px solid #030303;
  font-family: "Graphik-Medium";
}
.livefrom-tab-blk .nav-tabs {
  border-bottom: 0;
}
.livefrom-tab-blk .nav-tabs .nav-link:hover,
.livefrom-tab-blk .nav-tabs .nav-link:focus {
  border-color: #030303;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 5px !important;
}

//.modal{
.modal.modalEventform {
  //#modalCustomevent.modal-dialog{
  .locationDiv {
    padding: 25px 25px 12.5px 25px;
    border: 1px solid #ccc;
    // background: #fdf1ec;
    box-shadow: 0px 0px 5px rgba(169, 169, 169, 0.9);
    margin-bottom: 20px;
    &.paddingBottom {
      padding-bottom: 25px;
    }
  }
  .btn-group {
    display: block;
    text-align: center;
    padding: 0 0 20px;
    .btn {
      min-width: 230px;
      font-weight: bold;
      font-size: 15px;
      line-height: 1;
      padding: 15px;
      margin-top: 25px;
      text-transform: uppercase;
      &:focus {
        box-shadow: none !important;
      }
    }
    .btn-primary {
      background: #c83900;
      border-color: #c83900;
    }
    .btn-outline-primary {
      border-color: #c83900;
      color: #c83900;
      &.activeSort {
        background: #c83900;
        color: white;
      }
    }
  }
  .modal-dialog {
    .modal-content {
      background: #f3f3f3;
      padding: 35px 20px 35px;
      padding: 20px;
      border-radius: 3px;
      border: 0;
      .modal-header {
        padding: 0 0 15px;
        h2 {
          font-size: 24px;
          line-height: 30px;
          font-family: $font-semibold;
          color: #000;
          span.mhDropndrop {
            font-weight: normal;
            font-size: 14px;
            line-height: 31px;
            font-family: $font-regular;
            background: url("../images/drag-and-drop.png") no-repeat left center;
            background-size: 32px 30px;
            padding-left: 41px;
            margin: 7px 0 0;
            display: block;
          }
        }
        .close {
          width: 18px;
          height: 18px;
          background: url("../images/ic-popupclose18x18.jpg") no-repeat 0 0;
          padding: 0;
          border: 0;
          span:not(.sr-only) {
            display: none;
          }
        }
        .customSelect {
          display: flex;
          align-items: center;
          //margin:0 0 20px;
          label {
            font-size: 18px;
            line-height: 24px;
            font-family: $font-medium;
            color: #000;
            margin-right: 20px;
          }
        }
      }
      .modal-body {
        padding: 15px 0 0;
        .mceFormwrap {
          .form-group {
            padding: 5px 0 0px;
            label {
              font-size: 16px;
              line-height: 20px;
              font-family: $font-semibold;
              color: #000;
              margin: 0;
              display: block;
              &.mb15 {
                margin-bottom: 15px;
              }
              sup {
                color: #ff0000;
              }
            }
            .form-control[type="text"] {
              height: 56px;
              line-height: 56px;
            }
            .form-control {
              font-size: 16px;
              color: #000;
              border-color: #ccc;
              border-radius: 3px;
              margin: 0 0 10px;
              &::placeholder {
                color: #666;
              }
            }
            #eventSportType {
              div[class*="-control"] {
                margin-bottom: 20px !important;
                height: 56px;
              }
            }
            textarea.form-control {
              min-height: 185px;
              resize: none;
            }
            .fgTagswrap {
              padding: 0 0 20px;
              display: flex;
              flex-wrap: wrap;
              h3 {
                font-size: 16px;
                line-height: 30px;
                color: #000;
                margin: 0 0 10px;
              }
              .fgTagslist {
                padding-left: 5px;
                display: flex;
                flex-wrap: wrap;
                li {
                  padding: 0 5px;
                  margin: 0 0 10px;
                  p {
                    font-size: 14px;
                    line-height: 28px;
                    background: #fff;
                    padding: 0 20px;
                    border: 1px solid #ccc;
                    border-radius: 15px;
                    span {
                      padding-right: 20px;
                      position: relative;
                      i {
                        width: 10px;
                        height: 100%;
                        background: url("../images/ic-close10x10.jpg") no-repeat
                          left center;
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 0;
                      }
                    }
                  }
                }
              }
            }
            .customSelect {
              margin: 0 0 0px;
              &:first-child {
                margin-left: 0;
              }
              div[class*="-control"] {
                height: 56px;
                margin-bottom: 10px;
              }
            }
            .react-datepicker-wrapper {
              display: block;
              .react-datepicker__input-container {
                display: block;
                &:after {
                  width: 18px;
                  height: 100%;
                  background: url("../images/ic-calender18x18.jpg") no-repeat
                    left center;
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 20px;
                }
              }
            }
            .react-datepicker__triangle {
              left: 50% !important;
            }
            .form-check {
              min-height: 22px;
              padding: 0;
              margin: 0 0 10px;
              input[type="checkbox"] {
                display: none;
                &:checked + label {
                  i {
                    border-color: #c83900;
                    &:before {
                      opacity: 1;
                    }
                  }
                }
              }
              label {
                min-height: 22px;
                font-size: 14px;
                line-height: 1;
                padding-left: 25px;
                padding-top: 6px;
                margin: 0;
                vertical-align: top;
                position: relative;
                cursor: pointer;
                font-family: "poppinsmedium";
                display: inline-block;
                i {
                  width: 22px;
                  height: 22px;
                  background: #fff;
                  border: 1px solid #ccc;
                  border-radius: 3px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  transition: all 0.15s;
                  &:before {
                    width: 100%;
                    height: 100%;
                    background: url("../images/ic-checkbox-checked.jpg")
                      no-repeat center;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: all 0.15s;
                  }
                }
              }
            }
            .fgInner {
              display: flex;
              li {
                margin-bottom: 0px;
                margin-right: 20px;
                position: relative;
                &:first-child {
                  margin-right: 30px;
                  &:after {
                    width: 30px;
                    height: 100%;
                    font-size: 24px;
                    line-height: 56px;
                    color: #000;
                    text-align: center;
                    content: ":";
                    position: absolute;
                    top: 0;
                    left: 100%;
                  }
                }
                &:last-child {
                  margin-right: 0;
                }
                .form-control {
                  width: 60px;
                  padding: 0;
                  margin: 0;
                  text-align: center;
                }
                p {
                  width: 50px;
                  border: 1px solid #ccc;
                  border-radius: 3px;
                  a {
                    font-size: 14px;
                    line-height: 27px;
                    color: #000;
                    background: #fff;
                    display: block;
                    text-align: center;
                    border-radius: 0 0 3px 3px;
                    &:first-child {
                      line-height: 26px;
                      border-bottom: 1px solid #ccc;
                      border-radius: 3px 3px 0 0;
                    }
                  }
                  a.active {
                    background: #fdd1be;
                  }
                }
              }
            }
          }
          .fg-PnCWrap {
            padding: 15px 0;
            border-top: 1px solid #ccc;
            .form-group {
              font-size: 0;
              padding: 0;
              margin: 10px 0 0;
              &:first-child {
                margin-top: 0;
              }
              label {
                min-width: 78px;
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
              }
              .form-control {
                width: 310px;
                height: 42px;
                line-height: 42px;
                margin-bottom: 0;
                display: inline-block;
                vertical-align: middle;
              }
              .btn {
                font: 16px/16px "poppinssemibold";
                color: #fff;
                padding: 13px 20px 11px;
                margin-left: 10px;
                background: #c83900;
                border-color: #c83900;
                &:before {
                  width: 13px;
                  height: 13px;
                  background: url("../images/ic-plus_13x13.png") no-repeat 0 0;
                  content: "";
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 10px;
                  margin-top: -2px;
                }
              }
              .ulChannels {
                font-size: 0;
                margin-left: 88px;
                display: block;
                li {
                  padding: 10px 0 0;
                  margin-right: 5px;
                  display: inline-block;
                  vertical-align: top;
                  span {
                    font-size: 14px;
                    line-height: 1;
                    background: #fff;
                    padding: 8px 15px 6px;
                    border: 1px solid #ccc;
                    display: block;
                    text-transform: uppercase;
                    border-radius: 15px;
                    i {
                      width: 10px;
                      height: 10px;
                      background: url("../images/ic-close10x10.jpg") no-repeat
                        left center;
                      margin-left: 10px;
                      display: inline-block;
                      vertical-align: middle;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
          .locationDiv {
            .fg-PnCWrap {
              border-bottom: 1px solid #ccc;
              margin: 15px 0 10px;
            }
          }
          .previewPoster {
            padding: 0 0 10px;
            margin: 0 0 20px;
            figure {
              width: 100%;
              display: flex;
              vertical-align: top;
              justify-content: center;
              img {
                height: 300px;
                width: 100%;
                object-fit: cover;
                object-position: center;

                &#imgSquareposter {
                  width: 300px;
                }
              }
            }
            figure.logoFigure {
              img {
                width: 27%;
                height: auto;
              }
            }
          }
          .editPosterlink {
            font-size: 16px;
            line-height: 22px;
            color: #c83900;
            margin-left: 0px;
            //display: inline-block;
            vertical-align: top;
            font-weight: bold;
            float: right;
          }
          .fgwSelecteventlogos {
            padding: 15px 0;
            border-width: 1px 0;
            border-style: solid;
            border-color: #ccc;
            margin: 0 0 20px;

            .addLogoImage {
              cursor: pointer;
            }

            #eventLogoupload {
              height: 0;
              visibility: hidden;
              padding: 0;
              margin: 0;
              width: 0;
            }
            .editIcon {
              font-weight: bold;
              color: #c83900;
              cursor: pointer;
              float: right;
            }
            .sevList {
              margin: 0 -15px;
              display: flex;
              flex-wrap: wrap;
              position: relative;
              padding-right: 0;
              justify-content: center;
              li {
                padding: 0 15px;
                text-align: center;
                .sevBox {
                  figure {
                    min-height: 92px;
                    margin: 0 0 5px;
                  }
                  h4 {
                    font-size: 16px;
                    line-height: 20px;
                    color: #000;
                  }
                }
              }
              .addEventslogo {
                img {
                  margin-top: 10px;
                }
                p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #c83900;
                  display: block;
                  padding: 10px 0 0 0;
                  font-weight: bold;
                }
              }
            }
            .customEventLogoImg {
              width: 185px;
              height: 135px;
              object-fit: contain;
            }
          }
          //.fgwDatetimewrap {}
          .form-action {
            padding: 25px 0 0;
            text-align: center;
            border-top: 1px solid #ccc;
            .btn {
              min-width: 48%;
              font-size: 16px;
              line-height: 1 !important;
              font-family: $font-semibold;
              color: #fff;
              padding: 15px 20px 10px;
              text-transform: uppercase;
            }
            .btn-primary {
              background: #c83900;
              border-color: #c83900;
            }
            .btn-secondary {
              background: #1b1f27;
              border-color: #1b1f27;
            }
          }
          .form-check.fcUpdatetitle {
            min-height: 1px;
            margin: 0 0 15px;
            max-height: 1em;
            #overWriteTitleCheckbox {
              display: inline-block;
              cursor: pointer;
              vertical-align: top;
              float: none;
              &:focus {
                border-color: #000000 !important;
              }
              &:checked {
                background-color: #c83900;
                border-color: #c83900 !important;
                &:focus {
                  border-color: #c83900 !important;
                }
              }
            }
            .overWriteTitleCheckboxLabel {
              padding-left: 5px;
              //padding-top: 5px;
              padding-top: 1px;
              //font-weight: normal;
              font-size: 13px;
              font-family: "poppinsregular";
              //margin-top: -1px !important;
              min-height: 1px;
              line-height: 1.23em;
            }
          }
          .addLocationButton {
            font-size: 15px;
            padding: 12.5px 25px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 25px;
            &:focus {
              box-shadow: none !important;
            }
            .plusButton {
              padding: 0 0 0 20px !important;
              &::before {
                width: 15px !important;
                height: 18px !important;
              }
            }
          }
          .meridianAnchor {
            font-size: 12px !important;
            line-height: 1 !important;
            padding: 7px 0;
            &.active {
              background: #c83900 !important;
              color: white !important;
            }
          }
          .deleteColumn {
            text-align: right;
            margin-top: 12.5px;
            color: #999;
            font-size: 14px;
            line-height: 1px;
            font-family: $font-medium;
            span {
              cursor: pointer;
            }
            .fa {
              font-size: 20px;
              margin-right: 2.5px;
            }
          }
        }
        .mbCard {
          padding: 20px 0;
          border-bottom: 1px solid #ccc;
          margin: 0 0 25px;
          .mbcInner {
            figure {
              float: left;
              width: 27%;
              padding: 0 15px 0 20px;
              text-align: center;
            }
            .chAside {
              float: left;
              width: 73%;
              padding: 0 20px 0 15px;
              h3 {
                font-size: 18px;
                line-height: 24px;
                font-family: $font-semibold;
                color: #000;
                margin: 0 0 10px;
              }
              p {
                font-size: 14px;
                line-height: 20px;
                color: #000;
              }
              .btn-group {
                padding: 20px 0 0;
                .btn {
                  font-size: 12px;
                  line-height: 28px;
                  padding: 0 30px;
                  border-width: 1px;
                  border-radius: 15px;
                  margin-left: 20px;
                  &:first-child {
                    margin-left: 0;
                  }
                }
                .btn-default {
                  color: #c83900;
                  background: #fff;
                  border-color: #c83900;
                }
              }
            }
          }
        }
        .mbEventlist {
          ul {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0 10px;
            margin: 0 0px;
            li {
              padding: 0px;
              img {
                width: 100%;
                height: 300px !important;
                object-fit: cover;
              }
              .elBox {
                position: relative;
                .elbEdit {
                  //font-size: 12px;
                  //padding: 2px 7px;
                  //line-height: normal;
                  //border-radius: 5px;
                  width: 40px;
                  height: 40px;
                  font-size: 20px;
                  line-height: 40px;
                  color: #fff;
                  background: #c83900;
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 1;
                  border-radius: 20px;
                  text-align: center;
                }
                .elbFigure {
                  margin: 0 0 0px;
                }
                .elbContent {
                  padding: 20px 10px;
                  h3 {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: $font-semibold;
                    color: #000;
                    margin: 0 0 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }
                  p {
                    font-size: 14px;
                    line-height: 22px;
                    color: #000;
                  }
                }
              }
            }
          }
        }
        .mbLogolist {
          padding: 20px 0;
          border-width: 1px 0;
          border-style: solid;
          border-color: #ccc;
          margin: 0 0 25px;
          h4 {
            font-size: 16px;
            line-height: 20px;
            font-family: $font-semibold;
            color: #000;
            margin: 5px 0 0;
          }
          ul {
            padding: 5px 0 0;
            li {
              float: left;
              padding: 0px 0 0;
              margin-left: 30px;
              text-align: center;
              &:first-child {
                margin-left: 0;
              }
              .mbllBox {
                cursor: pointer;
                figure {
                  //width: 100px;
                  // height: 90px;
                  margin: 0 0 0;
                  display: flex;
                  img {
                    align-self: center;
                    width: 150px;
                    height: auto;
                    object-fit: contain;
                    transition: all 0.1s linear;

                    &.selectedLogo {
                      border: 4px solid #c83900e0;
                      border-radius: 10px;
                      padding: 5px;
                    }
                  }
                }
                h5 {
                  font-size: 14px;
                  line-height: 20px;
                  font-family: $font-medium;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-dialog#modalFeatureevent {
    .featureEventTypeFile {
      height: 40px;
      line-height: 29px;
      font-size: 15px;
    }
  }
  .modal-dialog#modalFeatureevent,
  .modal-dialog#modalEditevent {
    .modal-content {
      .modal-body {
        padding-top: 0;
        .mceFormwrap {
          .form-group {
            label {
              font-weight: normal;
            }
            // input[type="file"].form-control {
            //   min-height: 75px;
            //   border-style: dashed;
            // }
          }
          .form-action {
            .btn {
              font-size: 16px;
            }
            .btn-primary {
              float: left;
            }
            .btn-secondary {
              float: right;
            }
          }
        }
      }
    }
  }
  .modal-dialog#modalEditevent {
    .modal-content {
      .modal-body {
        .mceFormwrap {
          .previewPoster {
            margin: 0 0 10px;
          }
        }
      }
    }
  }
  .modal-dialog#modalFeatureEventlist {
    .modal-content {
      // background: #fdf1ec;
      border: 1px solid #ccc;
      .tooltipDescription {
        font-size: 18px;
        font-weight: bold;
        text-decoration: underline;
      }
      .modal-header {
        padding: 15px 0 40px;
        h2 {
          font-size: 30px;
          //line-height: 36px;
        }
        .close {
          background-image: url("../images/ic-popupclose_bglrose18x18.png");
          position: absolute;
          top: 30px;
          right: 30px;
        }
        .btn-group-sort {
          .btn-group {
            .btn {
              margin-left: 15px;
              border-radius: 0.25rem;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
      .grid {
        position: relative;
      }
      .item {
        display: block;
        position: absolute;
        width: auto;
        min-height: 100px;
        margin: 10px;
        width: calc(33% - 20px) !important;
        z-index: 1;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        color: #555;
        cursor: grab;
        &.rectangleItem {
          width: calc(50% - 20px - 1%) !important;
        }
        &.squareItem {
          width: calc(25% - 20px - 1%) !important;
        }
        &.muuri-item-dragging {
          cursor: grabbing;
        }
      }
      .item.muuri-item-dragging {
        z-index: 3;
        background: rgba(255, 255, 255, 0.85);
      }
      .item.muuri-item-releasing {
        z-index: 2;
        background: rgba(255, 255, 255, 0.85);
      }
      .item.muuri-item-hidden {
        z-index: 0;
      }
      .item-content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.modal-90w {
  width: 90% !important;
}
.vidfile1 {
  border: 1px solid #000;
  background: #000;
  line-height: 0;
  width: 240px;
  height: 152px;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.modal-dialog {
  width: 100 !important;
  height: 60%;
  margin-top: 5%;
  padding: 0;
}

.modal-content {
  width: auto !important;
  height: auto;
  border-radius: 0;
}
.strategy-close-padding {
  padding-right: 37px !important;
}

@media (max-width: 1259px) and (min-width: 1200px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1080px !important;
    margin-left: -25vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 320px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 420px;
    bottom: -34px;
  }
  .video-dashboard-width {
    width: 33%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 41px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 3.5%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 11% !important;
  }
}
@media (max-width: 1280px) and (min-width: 1260px) {
  .video-card {
    padding: 20px;
    height: 320px !important;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 500px;
    bottom: -34px;
  }
  .video-dashboard-width {
    width: 33%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 55px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 130%;
  }
  .strategy-type-toggle-1 {
    width: 3.5%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 10% !important;
  }
}
@media (max-width: 1300px) and (min-width: 1281px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1200px !important;
    margin-left: -27vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 510px;
    bottom: -34px;
  }
  .video-dashboard-width {
    width: 33%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 55px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 130%;
  }
  .strategy-type-toggle-1 {
    width: 3.5%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 10% !important;
  }
}
@media (max-width: 1366px) and (min-width: 1301px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1230px !important;
    margin-left: -27vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 560px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 33%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 75px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 3%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 10% !important;
  }
}
@media (max-width: 1440px) and (min-width: 1367px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1300px !important;
    margin-left: -27vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 620px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 33%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 80px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 3%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 9% !important;
  }
}
@media (max-width: 1500px) and (min-width: 1441px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1450px !important;
    margin-left: -30vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 650px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 32.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 90px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.8%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 9% !important;
  }
}
@media (max-width: 1580px) and (min-width: 1501px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1450px !important;
    margin-left: -30vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 760px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 25%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 20px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.6%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 9% !important;
  }
}
@media (max-width: 1600px) and (min-width: 1581px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1450px !important;
    margin-left: -30vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 760px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 24.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 60px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.6%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 9% !important;
  }
}
@media (max-width: 1780px) and (min-width: 1601px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1750px !important;
    margin-left: -33vw;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 765px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 24.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 75px !important;
  }
  .strategy-btn {
    padding-top: 16% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.3%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 8% !important;
  }
}
@media (max-width: 1850px) and (min-width: 1781px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1750px !important;
    margin-left: -33vw;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 765px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 24.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 75px !important;
  }
  .strategy-btn {
    padding-top: 13% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.3%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 8% !important;
  }
}
@media (max-width: 1920px) and (min-width: 1851px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1750px !important;
    margin-left: -33vw;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 765px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 24.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 100px !important;
  }
  .strategy-btn {
    padding-top: 13% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.1%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 8% !important;
  }
}
// @media (max-width: 1920px) and (min-width: 1921px) {
//   .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
//     border: 1 px solid #ccc;
//     width: 1750px !important;
//     margin-left: -33vw;
//   }
//   .video-card {
//     padding: 20px;
//     height: 330px;
//     width: 280px;
//   }
//   .display-rows-placement {
//     position: relative;
//     left: 1030px;
//     bottom: -35px;
//   }
// }
@media (max-width: 2300px) and (min-width: 1921px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 1750px !important;
    margin-left: -33vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 1030px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 19.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 60px !important;
  }
  .strategy-btn {
    padding-top: 13% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.2%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 8% !important;
  }
}
@media (max-width: 2900px) and (min-width: 2301px) {
  .modal.modalEventform .modal-dialog#modalFeatureEventlist .modal-content {
    border: 1 px solid #ccc;
    width: 2350px !important;
    margin-left: -37vw;
    height: auto;
  }
  .video-card {
    padding: 20px;
    height: 330px;
    width: 280px;
  }
  .display-rows-placement {
    position: relative;
    left: 1030px;
    bottom: -35px;
  }
  .video-dashboard-width {
    width: 19.4%;
  }
  .vidblk .vid-mainblk .vid-contblk {
    padding-top: 31px !important;
    padding-bottom: 31px !important;
    padding-left: 60px !important;
  }
  .strategy-btn {
    padding-top: 13% !important;
  }
  .strategy-live-fund-1 {
    width: 99%;
  }
  .strategy-type-toggle {
    vertical-align: middle;
    margin-top: 160%;
  }
  .strategy-type-toggle-1 {
    width: 2.2%;
  }
  .toggle-slider-left {
    margin-left: 10px !important;
    width: 8% !important;
  }
}
.vidblk .vid-mainblk .vid-contblk .memcontblk .vidfileinfo {
  width: 250px;
}
.video-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 30px;
}
.video-width {
  width: 280px;
  margin-right: 20px;
  position: absolute;
}
.video-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.display-select {
  padding: 5px;
}
.showcaseblk .tableblk .table-datatable__root {
  margin-top: 40px;
}
.modalblkk .modal-content .modal-body .firstblk aside label {
  padding-bottom: 5px;
}
.position-video {
  width: 400px;
  padding: 40px;
}

.modalblkk .modal-content {
  position: absolute;
  top: 10%;
  left: 0%;
}
.btnsave-video {
  padding: 10px 10px 10px;
  border-radius: 12px;
  border: 1px solid #555;
  outline: 0;
  box-shadow: none;
  z-index: 3;
  font: 20px/20px $font-medium;
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  background: #555;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}
a {
  cursor: pointer;
}
.call-details-notes {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
DatePicker::placeholdertext {
  font: 13px "Graphik-Medium" !important;
  color: grey !important;
}

.legs-series {
  color: $tc-gray;
  background: transparent;
  border: 1px solid #5d5d5d;
  font: 15px/20px $font-medium;
  border-radius: 12px;
  padding: 12px;
}

.legs-series-livefunds {
  color: $tc-gray;
  background: transparent;
  border: 1px solid #5d5d5d;
  font: 15px/20px $font-medium;
  border-radius: 12px;
  padding: 12px;
  width: 100% !important;
}

.stop-loss-calls-1 {
  padding-left: 0px !important;
  height: 0% !important;
  border-left: none !important;
}

.stop-loss-calls-2 {
  padding-top: 0px !important;
  .css-26l3qy-menu {
    width: 93% !important;
  }
  .css-1wa3eu0-placeholder {
    color: hsl(0, 0%, 50%) !important;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    box-sizing: border-box;
  }
}

.close-btn2 {
  background: url("../images/close-icon.png") no-repeat;

  height: 21px;
  cursor: pointer;
  margin-top: 45px;
  margin-left: -35px;
}

.bundle-scrip-head {
  height: 25px !important;
}

.add-strategy-button {
  cursor: pointer;
}
.close-btn-width {
  padding: 2px !important;
}
.strategy-close-padding {
  padding-right: 37px !important;
}
.close-btn3 {
  background: url("../images/close-icon.png") no-repeat;
  width: 21px;
  height: 21px;
  cursor: pointer;
  float: right;
  margin-top: 45px;
  margin-left: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
}

.leg-input-gray-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
  cursor: no-drop !important;
}
.slider:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 26px;
  border: 1px solid grey;
  border-radius: 3px;
  background-color: white;
  -webkit-transition: 0.1s;
  transition: 0.1s;
}

.leg-input-gray-toggle .slider:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 26px;
  border: 1px solid grey;
  border-radius: 3px;
  background-color: #d3d3d3;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  cursor: no-drop !important;
}

input:checked + .slider {
  background-color: #808080;
}

input:focus + .slider {
  box-shadow: 0 0 1px #808080;
}

input:checked + .slider:before {
  -webkit-transform: translateX(35px);
  -ms-transform: translateX(35px);
  transform: translateX(35px);
}

.toggle-slider {
  margin-left: 10px;
  margin-top: 10px;
}

.gray-bg .css-1fhf3k1-control {
  background-color: #d3d3d3 !important;
}

.gray-bg .css-14jk2my-container {
  background-color: #d3d3d3 !important;
}
.gray-bg .css-107lb6w-singleValue {
  color: grey !important;
}

.leg-input-gray {
  background-color: #d3d3d3 !important;
  color: grey !important;
}
.gray-bg .legs-series-livefunds {
  background-color: #d3d3d3 !important;
  color: grey !important;
}
.head-gray-bg {
  color: #808080 !important;
}
.gray-leg-bg {
  background-color: #d3d3d3;
  border-radius: 10px;
}
.white-bg {
  background-color: white !important;
}
.padd-top {
  padding-top: 20px;
}
.padd-bot {
  padding-bottom: 20px;
}
.black-text .legs-series-livefunds {
  color: black !important;
}

.exit-width {
  margin-left: 20px;
}
.call-field-header1 {
  font: 14px/20px "Graphik-Medium";
  margin-bottom: 10px;
}
.colmb {
  margin-bottom: 20px;
}
.call-leg-grey .css-14jk2my-container {
  background-color: #d3d3d3 !important;
  color: #808080 !important;
}
.call-leg-grey .css-1fhf3k1-control {
  background-color: #d3d3d3 !important;
  color: #808080 !important;
}
.legs-option-call-grey {
  color: #808080 !important;
}
.legs-option-call-black {
  color: black !important;
}
.option-rgt-blk
  .leg-blk
  .form-blk
  .legs-option-call-grey
  .form-group1
  .legsItemWidth1 {
  color: #808080 !important;
}
.option-rgt-blk
  .leg-blk
  .form-blk
  .legs-option-call-black
  .form-group1
  .legsItemWidth1 {
  color: black !important;
}
.black-text {
  color: black !important;
}
.legs-option-call-grey .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3px;
  cursor: no-drop !important;
}

.legs-option-call-grey .slider:before {
  position: absolute;
  content: "";
  height: 34px;
  width: 26px;
  border: 1px solid grey;
  border-radius: 3px;
  background-color: #d3d3d3;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  cursor: no-drop !important;
}

.td-action {
  width: 50px;
  text-align: left;
}
.td-series {
  width: 230px;
  text-align: center;
}
.td-center {
  width: 90px;
  text-align: center;
}
.td-center1 {
  width: 90px;
  text-align: center;
}
.td-action1 {
  width: 100px;
  text-align: center;
}
.leftComponentWidth1 {
  width: 550px !important;
}
.rightComponentWidth1 {
  width: 530px !important;
}
.table-td {
  margin-top: -65px;
}
.table-th-exit {
  padding-left: 15px;
}

@media (max-width: 1359px) and (min-width: 1200px) {
  .leftComponentWidth1 {
    width: 500px !important;
  }
  .rightComponentWidth1 {
    width: 450px !important;
  }
}

.live-videos-leftMargin {
  margin-left: -50px !important;
}

.close-btn4 {
  background: url("../images/close-icon.png") no-repeat;
  width: 21px;
  height: 21px;
  cursor: pointer;
  margin-top: 45px;
}

.cls-bundles {
  margin-left: 0px !important;
  display: inline-block;
  vertical-align: top;
  padding: 0 0px 0px 0px !important;
  width: 2% !important;
}

.exit-box {
  font-size: 12px;
  line-height: 12px;
  color: #fff;
  background: #a1a1a1;
  padding: 2px;

  border-radius: 4px;
  border: 1px solid gray;
  margin-left: 10px;
}

.td-display-block {
  display: block;
  float: left !important;
}

.field-disabled {
  background: 	#D3D3D3!important;
  color: #000;
  border: 1px solid #5d5d5d;
  font: 15px/20px "Graphik-Medium";
  border-radius: 12px;
  padding: 12px;
  text-align: center;
}


// order tracking component > start

.tab-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-bottom: 20px;
}

.filter-btn {
  // display: block;
  background: #5d5d5d;
  border-radius: 5px;
  font: 16px/20px "Graphik-Medium";
  color: #fff;
  text-align: center;
  padding: 10px;
}

.menu-item {
  color: black;
  cursor: pointer;
  margin-right: 1px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  font-family: Graphik-Medium;
}

.period-item {
  border-radius: 6px;
  margin-right: 10px;
}

.menu-item.active {
  color: white;
  background-color: gray;
}
.filter-dropdown {
  display: flex;
  // justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  // gap:2px;
  // margin: 20px 0;
}
.date-wrap{
  margin-bottom: 20px;
}

.filter-dropdown .dropdown {
  margin: 10px 10px 10px 0px;
}

.bs-date{
  margin-right: 10px;
}

.bs-date .date-input {
  border-radius: 0;
  padding: 5px;
  display: inline-block;
  width: 100%;
}

.tab-head {
  margin: 30px 0;
  padding: 0;
}

.order-table-title{
  font-size: 25px;
  font-weight: 600;
  padding: 0;
  margin-bottom: 20px;
  color: #555555;

}

.admin-order-tracking-page table thead {
  background: #5d5d5d;
  border-right: 1px solid #5d5d5d;
}

.admin-order-tracking-page table thead tr td {
  color: #fff;
  padding: 12px;
  font: 16px/20px "Graphik-Medium";
  text-align: center;
  border-right: 1px solid #fff;
  vertical-align: middle;
  background: $thead-bg;

}


.pagination-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-wrap li{
  margin: 0 10px;
  padding: 5px 15px;
  border-radius: 8px;
  cursor: pointer;
}

.pagination-btn{
  padding: 2px 6px;
  border-radius: 12px;
  background: unset;
  border: 1px solid gray;
}

.period-wrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.period-wrap  .orders-count{
  margin-right: 18px;
}

.period-wrap  .orders-count .order-label{
  font-size: 20px;
  font-weight: 600;
}

.period-wrap  .orders-count .order-value{
  font-size: 20px;
  font-weight: 600;
}

.table-wrap{
  overflow-x: auto;
}
.order-table{
  position: relative;
  margin-bottom: 50px;
}
.order-table .cm-no-data{
  position: absolute;
  text-align: center;
  width: 100%;
  top: 4rem;
}
.order-table .no-data-text{
  color: #555555;
    padding: 12px;
    font: 16px/20px "Graphik-Regular";
    border: 1px solid #5d5d5d;

}
.btn-wrap{
  text-align: end;
}

.export-btn{
  padding: 6px;
  border-radius: 8px;
  margin-bottom: 10px;
  background: #5d5d5d;
  color: white;
  font-weight: 500;
}

.form-check{
  font-size: 1.5rem;
}
// order tracking component > end
